var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login login-new-pass",on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.changePassword($event)}}},[_c('div',{staticClass:"login-inner"},[_c('div',{staticClass:"card card-login card-new-pass"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"logo-color"}),_vm._v(" "),_c('img',{attrs:{"src":require("@assets/logos/o-logo-color.svg"),"alt":"logo"}})]),_vm._v(" "),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"rules":{required: true, min: 8, max: 50}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t((_vm.translationsPrefix + ".newPasswordForgotten.newPassword")),"type":{'is-danger': errors.length},"message":errors,"custom-class":"required"}},[_c('b-input',{attrs:{"name":"new_password","type":"password","icon-pack":"icon-custom","icon":"lock lock-grey-lighter","placeholder":_vm.$t((_vm.translationsPrefix + ".newPasswordForgotten.introPassword"))},model:{value:(_vm.user.new_password),callback:function ($$v) {_vm.$set(_vm.user, "new_password", $$v)},expression:"user.new_password"}})],1)]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"rules":{required: true, is: _vm.user.new_password}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-field',{attrs:{"label":_vm.$t((_vm.translationsPrefix + ".newPasswordForgotten.confirmPassword")),"type":{'is-danger': errors.length},"message":_vm.confirmPasswordMessage(failedRules),"custom-class":"required"}},[_c('b-input',{attrs:{"name":"confirmPassword","type":"password","icon-pack":"icon-custom","icon":"lock lock-grey-lighter","placeholder":_vm.$t((_vm.translationsPrefix + ".newPasswordForgotten.introPassword"))},model:{value:(_vm.user.new_password_copy),callback:function ($$v) {_vm.$set(_vm.user, "new_password_copy", $$v)},expression:"user.new_password_copy"}})],1)]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"card-actions is-paddingless"},[_c('div',{staticClass:"card-footer-item"},[_c('b-button',{staticClass:"is-fullwidth",class:{'is-loading': _vm.isLoading},attrs:{"type":"is-primary","disabled":invalid},on:{"click":_vm.changePassword}},[_c('span',[_vm._v("\n                  "+_vm._s(_vm.$t((_vm.translationsPrefix + ".newPasswordForgotten.save")))+"\n                ")])])],1)])])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }