<template>
  <div class="searcher">
    <slot />
    <div class="column is-narrow">
      <button :class="classResetButton" @click="reset">
        {{ textResetButton }}
      </button>
    </div>
    <div v-if="!liveSearch" class="column is-narrow column-button">
      <button :class="classSearchButton" @click="searchButtonClick">
        {{ textSearchButton }}
      </button>
    </div>
  </div>
</template>

<script>
import linq from 'js-linq'

export default {
  name: 'NbSearcher',
  props: {
    liveSearch: {
      type: Boolean,
    },
    autoReset: {
      type: Boolean,
    },
    classResetButton: {
      type: String,
      default: 'button is-danger u-mx-1 is-fullwidth',
    },
    classSearchButton: {
      type: String,
      default: 'button is-info is-outlined',
    },
    textResetButton: {
      type: String,
      default: 'Borrar Filtros',
    },
    textSearchButton: {
      type: String,
      default: 'Buscar',
    },
  },
  data () {
    return {
      filters: [],
      isNbSearcher: true,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$emit('filter', linq.from(this.filters).select('f => f._props').toArray())
    })
  },
  methods: {
    searchButtonClick () {
      this.$emit('filter', linq.from(this.filters).select('f => f._props').toArray())
    },
    reset () {
      if (this.autoReset) {
        this.filters.forEach(filter => {
          filter.$emit('update:value', filter.defaultValue)
        })
      }

      this.$nextTick(() => {
        this.$emit('reset', linq.from(this.filters).select('f => f._props').toArray())
      })
    },
  },
}

</script>
