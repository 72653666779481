<template>
  <div v-if="!isLoading">
    <NewPasswordForgottenComponent :axios="axios"
                                   :translations-prefix="translationsPrefix"
                                   @change-password="changePassword($event)"
    />
  </div>
</template>

<script>
import supplierAxios from '@services/supplierAxios'
import UsersSuppliersService from '@services/UsersSuppliersService'
import NewPasswordForgottenComponent from '@components/NewPasswordForgotten'
import { Getters } from '@store/suppliers/types/getters'
import { mapGetters } from 'vuex'
import Notifications from '@/mixins/Notifications'

export default {
  name: 'NewPasswordForgotten',
  components: {
    NewPasswordForgottenComponent,
  },
  mixins: [Notifications],
  props: {
    redirect: {
      type: String,
      default: '/',
    },
  },
  data () {
    return {
      isLoading: false,
      axios: null,
    }
  },
  computed: {
    ...mapGetters({
      translationsPrefix: Getters.GET_TRANSLATIONS_PREFIX,
    }),
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.isLoading = true
      this.axios = supplierAxios
      this.isLoading = false
    },
    async changePassword (user) {
      this.isLoading = true
      try {
        await UsersSuppliersService.recoverPassword(user)
        this.showInfo(
          'is-success',
          this.$t(`${this.translationsPrefix}.newPasswordForgotten.correctMsg`),
          this.$router.push('/login'))
      } catch (error) {
        this.showInfo('is-danger', this.$t(`${this.translationsPrefix}.newPasswordForgotten.errorMsg`))
      }
      this.isLoading = false
    },
  },
}
</script>
