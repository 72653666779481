<template>
  <div class="card card-list card-suppliers card-table-full-height">
    <!--card header-->
    <header class="card-header">
      <p class="card-header-title">
        {{ $t('admin.suppliersList.title') }}
      </p>
    </header>
    <!--card filter-->
    <div class="card-filter">
      <nb-searcher class="card-filter-fields columns columns-filters"
                   :text-reset-button="$t('admin.suppliersList.deleteFilter')"
                   class-reset-button="button is-primary is-fullwidth"
                   live-search
                   auto-reset
                   @filter="search($event)"
                   @reset="search($event)"
      >
        <!-- code search -->
        <div class="column is-one-third-desktop">
          <nb-filter class="card-filter-field"
                     field="code"
                     operator="like"
                     :value.sync="filters.code"
          >
            <label class="label">{{ $t('admin.suppliersList.code') }}</label>
            <div class="control has-icons-right">
              <label for="search-input" />
              <b-input id="search-input"
                       v-model="filters.code"
                       icon-pack="icon-custom"
                       icon-right="icon-search icon-search-grey-lighter"
                       type="search"
                       :placeholder="$t('admin.suppliersList.codePlaceholder')"
              />
            </div>
          </nb-filter>
        </div>
        <!-- name search -->
        <div class="column is-one-third-desktop">
          <nb-filter class="card-filter-field"
                     field="name"
                     operator="like"
                     :value.sync="filters.search"
          >
            <label class="label">{{ $t('admin.suppliersList.search') }}</label>
            <div class="control has-icons-right">
              <label for="search-input" />
              <b-input id="search-input"
                       v-model="filters.search"
                       icon-pack="icon-custom"
                       icon-right="icon-search icon-search-grey-lighter"
                       type="search"
                       :placeholder="$t('admin.suppliersList.searching')"
              />
            </div>
          </nb-filter>
        </div>
      </nb-searcher>
    </div>
    <div class="card-content card-content-scroll">
      <b-table :data="suppliers"
               :loading="loading"
               :backend-sorting="true"
               :default-sort-direction="defaultSortDirection"
               :default-sort="defaultSortField"
               sticky-header
               class="table is-striped table-list-control table-scroll"
               @sort="sort(...arguments)"
      >
        <!-- Supplier Code -->
        <b-table-column v-slot="props"
                        field="code"
                        :label="$t('admin.suppliersList.code')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          {{ props.row.code }}
        </b-table-column>
        <!-- Supplier Name -->
        <b-table-column v-slot="props"
                        field="name"
                        :label="$t('admin.suppliersList.name')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          <router-link :to="`/proveedores/${props.row.id}`">
            {{ props.row.name }}
          </router-link>
        </b-table-column>
        <!-- Supplier NIF -->
        <b-table-column v-slot="props"
                        field="nif"
                        :label="$t('admin.suppliersList.nif')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          {{ props.row.nif }}
        </b-table-column>
        <!-- Supplier email -->
        <b-table-column v-slot="props"
                        field="email"
                        :label="$t('admin.suppliersList.email')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          {{ props.row.email }}
        </b-table-column>
        <!-- Supplier NIF -->
        <b-table-column v-slot="props"
                        field="phone"
                        :label="$t('admin.suppliersList.phone')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          {{ props.row.phone }}
        </b-table-column>
        <!-- Supplier address -->
        <b-table-column v-slot="props"
                        field="firstAddress"
                        :label="$t('admin.suppliersList.address')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          {{ props.row.firstAddress }}
        </b-table-column>
        <!--actions-->
        <b-table-column v-slot="props"
                        :label="$t('admin.suppliersList.actions')"
                        header-class="table-title"
                        centered
                        field="actions"
                        cell-class="table-actions"
        >
          <div class="table-item">
            <router-link :to="`/proveedores/${props.row.id}`" class="button button-square is-text">
              <i class="icon-custom icon-eye icon-eye-gray" />
            </router-link>
          </div>
        </b-table-column>
        <template #empty>
          <div class="has-text-centered">
            {{ $t('admin.suppliersList.noData') }}
          </div>
        </template>
      </b-table>
    </div>
    <div class="card-footer">
      <div class="card-footer-item">
        <b-pagination
          :total="infoPagination.total"
          :current.sync="infoPagination.current_page"
          order="is-centered"
          size="is-small"
          :simple="false"
          :per-page="infoPagination.per_page"
          @update:current="getSuppliers"
        />
      </div>
    </div>
  </div>
</template>
<script>
import SuppliersService from '@/services/SuppliersService'
import Notifications from '@/mixins/Notifications'
// import SupplierForm from '@views/admin/Suppliers/SupplierForm'
import { Getters } from '@store/admin/types/getters'
import { mapGetters } from 'vuex'

export default {
  name: 'SuppliersList',
  mixins: [Notifications],
  data () {
    return {
      filters: {},
      filtersToApply: [],
      suppliers: [],
      loading: false,
      perPage: 8,
      defaultSortDirection: 'asc',
      defaultSortField: 'name',
      sortDirection: null,
      sortField: null,
      infoPagination: { current_page: 1 },
    }
  },
  computed: {
    ...mapGetters({
      translationsPrefix: Getters.GET_TRANSLATIONS_PREFIX,
    }),
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.reset()
      this.getSuppliers()
    },
    reset () {
      this.sortDirection = this.defaultSortDirection
      this.sortField = this.defaultSortField
      this.filters = {}
      this.filtersToApply = []
    },
    sort (field, direction) {
      this.sortField = field
      this.sortDirection = direction
      this.getSuppliers()
    },
    async getSuppliers () {
      this.loading = true
      const order =
        this.sortField && this.sortDirection
          ? { field: this.sortField, direction: this.sortDirection }
          : null
      try {
        const results = await SuppliersService.index(
          this.filtersToApply,
          order,
          this.infoPagination.current_page
            ? this.infoPagination.current_page
            : 1,
          this.perPage
        )
        this.suppliers = results.data
        this.infoPagination = results
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    search (filtersEvent) {
      this.filtersToApply = filtersEvent
      this.infoPagination = { current_page: 1 }
      this.getSuppliers()
    },
    // openSupplierFormModal (idSupplier) {
    //   this.$buefy.modal.open({
    //     parent: this,
    //     component: SupplierForm,
    //     props: {
    //       idSupplier,
    //     },
    //   })
    // },
  },
}
</script>
