import Vue from 'vue'
import { init as initSentry } from '@sentry/browser'
import { Vue as SentryIntegrationVue } from '@sentry/integrations'
import { ExceptionCode } from './codes'

const processException = (sentryEvent, hint) => {
  if (hint.originalException) {
    switch (hint.originalException.exceptionCode) {
    case ExceptionCode.RefreshTokenExpiredOrRevoked: {
      const location = window.location
      const path = location.pathname

      // TODO: Faltaría comprobar dominio por si se configura distinto dominio pero mismo prefijo
      // TODO: En general reviar la forma de comprobar esto
      /* eslint-disable */
      let prefix = ''
      if (path.startsWith('/' + process.env.MIX_APP_SUPPLIERS_PREFIX)) {
        prefix = process.env.MIX_APP_SUPPLIERS_PREFIX
      }
      if (path.startsWith('/' + process.env.MIX_APP_ADMIN_PREFIX)) {
        prefix = process.env.MIX_APP_ADMIN_PREFIX
      }

      let redirectPath = location.pathname
      if (prefix.length) {
        prefix += '/'
        redirectPath = redirectPath.replace(prefix, '');
      }
      /* eslint-enable */

      window.location = `/${prefix}login?redirect=${redirectPath + location.search + location.hash}`
      return true
    }
    default:
      return false
    }
  }

  return false
}

export const init = () => {
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV === 'development') {
    Vue.config.errorHandler = (err, vm, info) => {
      Vue.util.warn(`Error in ${info}: "${err.toString()}"`, vm)
      console.error(err)
    }
  }

  // Sentry call orginal onerror
  // Not print errors in console
  window.onerror = () => true

  initSentry({
    // eslint-disable-next-line no-undef
    dsn: process.env.MIX_APP_SENTRY_DSN,
    // eslint-disable-next-line no-undef
    environment: process.env.MIX_APP_SENTRY_ENVIRONMENT,
    integrations: [new SentryIntegrationVue({ Vue, attachProps: true })],
    beforeSend: (sentryEvent, hint) => {
      if (processException(sentryEvent, hint)) {
        return null
      }

      // Only send to sentry at production
      // eslint-disable-next-line no-undef
      if (process.env.NODE_ENV === 'production') {
        return sentryEvent
      }

      // In others enviroments prints in browser console
      if (hint.originalException || hint.syntheticException) {
        console.error(hint.originalException || hint.syntheticException)
      }
      return null
    },
  })
}

export default { init }
