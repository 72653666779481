<template>
  <div class="box box-subheader">
    <div class="box-subheader-left">
      <div v-if="isLoaded" class="box-subheader-data" aria-label="box-subheader-data">
        <div v-if="structure.title" class="box-subheader-item box-subheader-item-title">
          <span :class="structure.titleClass">
            {{ structure.title }}
          </span>
        </div>
        <div v-for="(segment, index) in structure.segments" :key="index" class="box-subheader-item">
          <span :class="segment.titleClass">
            {{ segment.title }}
          </span>
          <span :class="segment.valueClass">
            {{ segment.value }}
            <span v-if="segment.additionalInformation" :class="segment.additionalInformationClass">
              {{ segment.additionalInformation }}
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="box-subheader-right">
      <div v-for="button in structure.buttons" :key="button.url" class="box-subheader-actions">
        <!--button action-->
        <router-link :to="button.url">
          <b-button :type="button.type" :icon-left="button.icon">
            {{ button.name }}
          </b-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'SubHeader',
  props: {
    structure: {
      type: Object,
      default: () => ({}),
    },
    nameDataDynamic: {
      type: String,
      default: null,
    },
    isLoaded: {
      type: Boolean,
    },
  },
}
</script>
