<template>
  <div class="card-block card-block-users-suppliers">
    <header class="card-header-section">
      <p class="card-header-title subtitle">
        {{ $t('admin.supplierForm.usersSuppliers.title') }}
      </p>
      <b-button icon-left="plus" type="is-primary button-square" @click="addRow(getNewVariable())" />
    </header>
    <!-- <ValidationObserver ref="observer" v-slot="{invalid}"> -->

    <div class="card-content">
      <b-table class="table is-striped table-scroll" sticky-header :data="value">
        <!-- Name  -->
        <b-table-column v-slot="props"
                        field="name"
                        :label="$t('admin.supplierForm.usersSuppliers.name')"
                        cell-class="table-data table-data--big"
                        header-class="table-title"
        >
          <b-field class="is-marginless">
            <b-input v-model="props.row.name"
                     type="text"
                     :disabled="props.row.disabled"
            />
          </b-field>
        </b-table-column>
        <b-table-column v-slot="props"
                        field="name"
                        :label="$t('admin.supplierForm.usersSuppliers.email')"
                        cell-class="table-data"
                        header-class="table-title"
        >
          <b-field class="is-marginless">
            <b-input v-model="props.row.email"
                     type="text"
                     :disabled="props.row.disabled"
            />
          </b-field>
        </b-table-column>
        <b-table-column v-slot="props"
                        field="actions"
                        cell-class="table-actions"
                        :label="$t('admin.supplierForm.usersSuppliers.actions')"
                        header-class="table-title"
        >
          <div class="table-item">
            <b-button class="button-square"
                      type="is-text"
                      icon-pack="icon-custom"
                      icon-left="icon-check icon-check-gray"
                      :disabled="props.row.disabled"
                      @click="createUserSupplier(props.row, props.index)"
            />
          </div>
          <div class="table-item">
            <b-button class="button-square"
                      type="is-text"
                      icon-pack="icon-custom"
                      icon-left="icon-trash icon-trash-primary"
                      @click="deleteUserSupplier(props.row.id, props.row.name, props.index)"
            />
          </div>
        </b-table-column>
        <template #empty>
          {{ $t('admin.supplierForm.usersSuppliers.noData') }}
        </template>
      </b-table>
    </div>
    <b-loading :is-full-page="isFullPage" :active.sync="loadingData" :can-cancel="true">
      <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin" />
    </b-loading>
  </div>
</template>
<script>
import SuppliersService from '@services/SuppliersService'
import DynamicTable from '@/mixins/DynamicTable'
import Notifications from '@/mixins/Notifications'
import { Getters } from '@store/admin/types/getters'
import { mapGetters } from 'vuex'

export default {
  name: 'UsersSuppliersForm',
  mixins: [DynamicTable, Notifications],
  props: {
    idSupplier: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      loadingData: false,
      isFullPage: false,
    }
  },
  computed: {
    ...mapGetters({
      translationsPrefix: Getters.GET_TRANSLATIONS_PREFIX,
    }),
  },
  methods: {
    getNewVariable () {
      return {
        name: '',
        email: '',
        supplier_id: this.idSupplier,
        disabled: false,
      }
    },
    createUserSupplier (userSupplier, index) {
      this.loadingData = true
      SuppliersService.createUserSupplier(userSupplier)
        .then(response => {
          this.showInfo('is-success', this.$t('admin.supplierForm.usersSuppliers.msgCorrect'))
          const newUserSupplier = response
          this.$set(newUserSupplier, 'disabled', true)
          this.changeRow(index, newUserSupplier)
          this.loadingData = false
        })
        .catch(
          () => {
            this.showInfo('is-danger', this.$t('admin.supplierForm.usersSuppliers.msgIncorrect'))
            this.loadingData = false
          })
    },
    confirmFunction (idUserSupplier, { index }) {
      SuppliersService.deleteUserSupplier(idUserSupplier)
        .then(
          () => {
            this.showInfo('is-success', this.$t('admin.supplierForm.usersSuppliers.msgCorrectDelete'))
            this.deleteRow(index)
          })
        .catch(
          () => {
            this.showInfo('is-danger', this.$t('admin.supplierForm.usersSuppliers.msgIncorrectDelete'))
          })
    },
    deleteUserSupplier (id, name, index) {
      if (id) {
        const deleteDialog = {
          id,
          aditionalData: { index },
          title: this.$t('admin.supplierForm.usersSuppliers.titleDialog'),
          message: this.$t('admin.supplierForm.usersSuppliers.messageDialog', { name }),
          onConfirmFunction: this.confirmFunction,
        }
        this.deleteDialog(deleteDialog)
      } else {
        this.deleteRow(index)
      }
    },
  },
}
</script>
