import adminAxios from '@services/adminAxios'
import { getListInfo } from '@/helpers/ServiceHelpers'

export default {
  index (filters, order, page, perPage = 10) {
    const info = getListInfo(filters, order, page, perPage)
    return adminAxios().get('/suppliers?embed=suppliers'+
      info.infoFilters + info.infoOrder + info.infoPage)
  },
  getSupplier () {
    return adminAxios().get('/supplier')
  },
  showSupplier (id) {
    return adminAxios().get(`/suppliers/${id}`)
  },
  getSuppliers () {
    return adminAxios().get('/suppliers')
  },
  createSupplier (data) {
    return adminAxios().post('/suppliers', data)
  },
  updateSupplier (id, data) {
    return adminAxios().put(`/suppliers/${id}`, data)
  },
  deleteSupplier (id) {
    return adminAxios().delete(`/suppliers/${id}`)
  },
  impersonate (id) {
    return adminAxios().get(`supplier/${id}/impersonate`)
  },
  // Routes for usersSuppliers
  indexUsers () {
    return adminAxios().get('/suppliers/users')
  },
  getUserSuppliers () {
    return adminAxios().get('/suppliers/users')
  },
  createUserSupplier (data) {
    return adminAxios().post('/suppliers/users', data)
  },
  updateUserSupplier (id, data) {
    return adminAxios().put(`/suppliers/users/${id}`, data)
  },
  deleteUserSupplier (id) {
    return adminAxios().delete(`/suppliers/users/${id}`)
  },
}
