<template>
  <div v-if="!loadingData" class="card card-table-full-height card-list card-order">
    <!--card content-->
    <b-tabs v-model="activeTab" position="is-left" size="is-medium" class="block tabs-buttons-actions">
      <!--Documents TAB-->
      <b-tab-item :label="$t('suppliers.orderForm.tabs.documents')"
                  :value="OrderFormActiveTab.Documents"
      >
        <OrderDocuments :num-order="numOrder" :id-order="order.id" />
      </b-tab-item>
      <!--Invoices TAB-->
      <b-tab-item :label="$t('suppliers.orderForm.tabs.invoices')"
                  :value="OrderFormActiveTab.Invoices"
      >
        <OrderInvoices :num-order="numOrder" :order-status="order.status" @new-invoice="setInvoicedStatus" />
      </b-tab-item>
      <!--Invoices TAB-->
      <b-tab-item :label="$t('suppliers.orderForm.tabs.status')"
                  :value="OrderFormActiveTab.Status"
      >
        <OrderStatus :num-order="numOrder" />
      </b-tab-item>
      <!--Invoices TAB-->
      <b-tab-item :label="$t('suppliers.orderForm.tabs.information')"
                  :value="OrderFormActiveTab.Information"
      >
        <OrderInformation :num-order="numOrder" />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import OrdersService from '@services/OrdersService'
import Notifications from '@/mixins/Notifications'
import OrderDocuments from '@components/Orders/OrderDocuments'
import OrderInvoices from '@components/Orders/OrderInvoices'
import OrderStatus from '@components/Orders/OrderStatus'
import OrderInformation from '@components/Orders/OrderInformation'
import { OrderFormActiveTab } from '@/enums/OrderFormActiveTab'
import { mapActions } from 'vuex'
import { Actions } from '@store/suppliers/types/actions'
import { DocumentStatus } from '@/enums/DocumentStatus'

export default {
  name: 'OrderForm',
  components: {
    OrderDocuments,
    OrderInvoices,
    OrderStatus,
    OrderInformation,
  },
  mixins: [Notifications],
  props: {
    numOrder: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      loadingData: false,
      isFullPage: false,
      order: {},
      OrderFormActiveTab,
      activeTab: OrderFormActiveTab.Documents,
    }
  },
  created () {
    this.init()
  },
  methods: {
    ...mapActions({
      setNumberIncidents: Actions.SET_NUMBER_INCIDENTS,
      setOrderStatus: Actions.SET_ORDER_STATUS,
    }),
    async init () {
      if (this.numOrder) {
        this.loadingData = true
        await this.getOrder()
        this.loadingData = false
      }
    },
    async getOrder () {
      await OrdersService.showOrder(this.numOrder).then(response => {
        this.order = response.data
        this.setNumberIncidents(this.order.num_incidents)
        this.setOrderStatus(this.order.status)
      })
    },
    setInvoicedStatus () {
      this.setOrderStatus(DocumentStatus.Invoiced)
    },
  },
}
</script>
