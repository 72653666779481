<template>
  <aside id="asideMenu" class="box box-sidebar box-sidebar-dark">
    <div class="box-actions">
      <!-- Suppliers -->
      <router-link to="/pedidos"
                   class="button button-box"
                   active-class="link-active"
      >
        <i class="icon icon-custom icon-provider icon-provider-white" />
        <span class="button-box-text">{{ $t('suppliers.suppliersSidebar.orders') }}</span>
      </router-link>
    </div>
  </aside>
</template>
<script>

export default {
  data () {
    return {}
  },
}
</script>
