<template>
  <div class="card card-list card-technics card-table-full-height">
    <!--card header-->
    <header class="card-header">
      <p class="card-header-title">
        {{ $t('admin.techsList.title') }}
      </p>
      <b-button type="is-dark" class="" @click="openTechFormModal = true">
        {{ $t('admin.techsList.newTech') }}
      </b-button>
    </header>
    <!--card filter-->
    <div class="card-filter">
      <nb-searcher class="card-filter-fields columns columns-filters"
                   :text-reset-button="$t('admin.techsList.deleteFilter')"
                   class-reset-button="button is-primary is-fullwidth"
                   live-search
                   auto-reset
                   @filter="search($event)"
                   @reset="search($event)"
      >
        <!-- name search -->
        <div class="column is-one-third-desktop">
          <nb-filter class="card-filter-field"
                     field="name"
                     operator="like"
                     :value.sync="filters.search"
          >
            <label class="label">{{ $t('admin.techsList.search') }}</label>
            <div class="control has-icons-right">
              <label for="search-input" />
              <b-input id="search-input"
                       v-model="filters.search"
                       icon-pack="icon-custom"
                       icon-right="icon-search icon-search-grey-lighter"
                       type="search"
                       :placeholder="$t('admin.techsList.searching')"
              />
            </div>
          </nb-filter>
        </div>
      </nb-searcher>
    </div>
    <!--card body-->
    <div class="card-content card-content-scroll">
      <b-table :data="techs"
               :loading="loading"
               :backend-sorting="true"
               :default-sort-direction="defaultSortDirection"
               :default-sort="defaultSortField"
               sticky-header
               class="table is-striped table-list-control table-scroll"
               @sort="sort(...arguments)"
      >
        <!-- Tech Name -->
        <b-table-column v-slot="props"
                        field="name"
                        :label="$t('admin.techsList.name')"
                        sortable
                        cell-class="table-data"
                        header-class="table-title"
        >
          <router-link :to="`/tecnicos/${props.row.id}`">
            {{ props.row.name }}
          </router-link>
        </b-table-column>
        <!-- Tech email -->
        <b-table-column v-slot="props"
                        field="email"
                        :label="$t('admin.techsList.email')"
                        cell-class="table-data"
                        sortable
                        header-class="table-title"
        >
          {{ props.row.email }}
        </b-table-column>
        <!-- Tech department -->
        <b-table-column v-slot="props"
                        field="department"
                        :label="$t('admin.techsList.department')"
                        cell-class="table-data"
                        sortable
                        header-class="table-title"
        >
          {{ props.row.department }}
        </b-table-column>
        <!-- Tech location -->
        <b-table-column v-slot="props"
                        field="location"
                        :label="$t('admin.techsList.location')"
                        cell-class="table-data"
                        sortable
                        header-class="table-title"
        >
          {{ props.row.location }}
        </b-table-column>
        <!--actions-->
        <b-table-column v-slot="props"
                        :label="$t('admin.techsList.actions')"
                        field="actions"
                        centered
                        header-class="table-title"
                        cell-class="table-actions"
        >
          <div class="table-item is-paddingless">
            <router-link :to="`/tecnicos/${props.row.id}`" class="button button-square is-text">
              <em class="icon-custom icon-eye icon-eye-gray" />
            </router-link>
          </div>
          <div class="table-item is-paddingless">
            <b-button
              class="button-square"
              icon-pack="icon-custom"
              icon-left="icon-trash icon-tras-gray"
              type="is-text"
              :disabled="deleteButtonDisabled(props.row.id)"
              @click="deleteTechs(props.row.id, props.row.name)"
            />
          </div>
        </b-table-column>
        <template #empty>
          <div class="has-text-centered">
            {{ $t('admin.techsList.noData') }}
          </div>
        </template>
      </b-table>
      <b-modal v-model="openTechFormModal" :can-cancel="false">
        <TechForm @created="closeTechFormModal">
          <b-button class="button-icon button-icon-close" @click="openTechFormModal = false">
            <em class="icon-custom icon-close icon-close-gray" />
          </b-button>
        </TechForm>
      </b-modal>
    </div>
    <!--card footer-->
    <div class="card-footer">
      <div class="card-footer-item">
        <b-pagination
          :total="infoPagination.total"
          :current.sync="infoPagination.current_page"
          order="is-centered"
          size="is-small"
          :simple="false"
          :per-page="infoPagination.per_page"
          @update:current="getTechs"
        />
      </div>
    </div>
  </div>
</template>
<script>
import UsersService from '@/services/UsersService'
import Notifications from '@/mixins/Notifications'
import TechForm from '@views/admin/Techs/TechForm'
import { Getters } from '@store/admin/types/getters'
import { mapGetters } from 'vuex'

export default {
  name: 'TechsList',
  components: {
    TechForm,
  },
  mixins: [Notifications],
  data () {
    return {
      filters: {},
      filtersToApply: [],
      techs: [],
      loading: false,
      perPage: 8,
      defaultSortDirection: 'asc',
      defaultSortField: 'name',
      sortDirection: null,
      sortField: null,
      infoPagination: { current_page: 1 },
      openTechFormModal: false,
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: Getters.GET_USER,
      translationsPrefix: Getters.GET_TRANSLATIONS_PREFIX,
    }),
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.reset()
      this.getTechs()
    },
    reset () {
      this.sortDirection = this.defaultSortDirection
      this.sortField = this.defaultSortField
      this.filters = {}
      this.filtersToApply = []
    },
    sort (field, direction) {
      this.sortField = field
      this.sortDirection = direction
      this.getTechs()
    },
    async getTechs () {
      this.loading = true
      const order =
        this.sortField && this.sortDirection
          ? { field: this.sortField, direction: this.sortDirection }
          : null
      try {
        const results = await UsersService.index(
          this.filtersToApply,
          order,
          this.infoPagination.current_page
            ? this.infoPagination.current_page
            : 1,
          this.perPage
        )
        // this.techs = results
        this.techs = results.data
        this.infoPagination = results
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    confirmFunction (id) {
      UsersService.deleteUser(id)
        .then(() => {
          this.showInfo('is-success', this.$t('admin.techsList.msgCorrect'))
          this.getTechs()
        })
        .catch(() => this.showInfo('is-danger', this.$t('admin.techsList.msgIncorrect')))
    },
    deleteTechs (id, name) {
      const deleteDialog = {
        id,
        title: this.$t('admin.techsList.titleDialog'),
        message: this.$t('admin.techsList.messageDialog', { name }),
        onConfirmFunction: this.confirmFunction,
      }
      this.deleteDialog(deleteDialog)
    },
    search (filtersEvent) {
      this.filtersToApply = filtersEvent
      this.infoPagination = { current_page: 1 }
      this.getTechs()
    },
    closeTechFormModal () {
      this.openTechFormModal = false
      this.getTechs()
    },
    deleteButtonDisabled (id) {
      return this.loggedUser.id === id
    },
  },
}
</script>
