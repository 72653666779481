<template>
  <form action=""
        class="card card-pass-recovery card-modal"
  >
    <ValidationObserver v-slot="{invalid}">
      <header class="card-header">
        <p class="card-header-title">
          {{ $t(`${translationsPrefix}.forgottenPasswordModal.tittleRecovery`) }}
        </p>
        <b-button class="button-icon button-icon-close" @click="$parent.close()">
          <i class="icon-custom icon-close icon-close-gray" />
        </b-button>
      </header>
      <div class="card-content">
        <p class="content">
          {{ $t(`${translationsPrefix}.forgottenPasswordModal.helpMsg`) }}
        </p>
        <ValidationProvider v-slot="{errors}" :rules="{required: true, email: true}">
          <b-field :label="$t(`${translationsPrefix}.forgottenPasswordModal.email`)"
                   :type="{'is-danger': errors.length}"
                   :message="errors"
                   custom-class="required"
          >
            <b-input v-model="email"
                     icon-pack="icon-custom"
                     icon="email email-grey-lighter"
                     :placeholder="$t(`${translationsPrefix}.forgottenPasswordModal.emailPlaceholder`)"
                     required
            />
          </b-field>
        </ValidationProvider>
      </div>
      <footer class="card-footer">
        <div class="card-actions">
          <div class="card-footer-item">
            <b-button type="is-dark" class="button" outlined @click="$parent.close">
              {{ $t(`${translationsPrefix}.forgottenPasswordModal.close`) }}
            </b-button>
          </div>
          <div class="card-footer-item">
            <b-button type="is-primary" class="button" :disabled="invalid" @click="recoveryPassword">
              {{ $t(`${translationsPrefix}.forgottenPasswordModal.send`) }}
            </b-button>
          </div>
        </div>
      </footer>
    </ValidationObserver>
  </form>
</template>
<script>
import Translations from '@/mixins/Translations'

export default {
  name: 'ForgottenPasswordModal',
  mixins: [Translations],
  data () {
    return {
      email: '',
    }
  },
  methods: {
    async recoveryPassword () {
      this.$emit('recover', this.email)
      this.$parent.close()
    },
  },
}
</script>
