import Vue from 'vue'
import Buefy from 'buefy'

const language = () => localStorage.getItem('language') ?? 'es'

const loadBuefy = () => {
  Vue.use(Buefy, {
    defaultFirstDayOfWeek: 1,
    defaultDatepickerYearsRange: [-10, 20],
    defaultIconPack: 'fas',
    defaultLocale: language(),
    customIconPacks: {
      fas: {
        iconPrefix: 'fa-',
        sizes: {
          default: 'fa-lg',
          'is-small': '',
          'is-medium': 'fa-2x',
          'is-large': 'fa-3x',
        },
      },
    },
  })
}

export default loadBuefy