<template>
  <form action=""
        class="card card-profile card-modal"
  >
    <!--CARD MODAL HEADER-->
    <header class="card-header">
      <p class="card-header-title">
        {{ $t(`${translationsPrefix}.userProfileModal.userDataAndPreferences`) }}
      </p>
      <b-button class="button-icon button-icon-close" @click="$parent.close()">
        <em class="icon-custom icon-close icon-close-gray" />
      </b-button>
    </header>
    <!--CARD MODAL CONTENT BODY-->
    <div class="card-body">
      <!--card profile-->
      <div class="card-content card-profile-info">
        <header class="card-sub-header">
          <p class="card-header-title subtitle">
            {{ $t(`${translationsPrefix}.userProfileModal.userData`) }}
          </p>
        </header>
        <div class="card-profile-data">
          <div class="card-profile-id">
            {{ getFirstLetter() }}
          </div>
          <div class="card-profile-user">
            <div class="card-profile-user-info">
              <span class="field__span title-med">
                {{ user ? user.name : '' }}
              </span>
            </div>
            <div class="card-profile-user-contact">
              <em class="email email-primary" />
              <span class="field__span card-email-address">
                {{ user ? user.email : '' }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <!--card preferences-->
      <div class="card-content card-preferences">
        <header class="card-sub-header">
          <p class="card-header-title subtitle">
            {{ $t(`${translationsPrefix}.userProfileModal.notificationsPreferences`) }}
          </p>
        </header>
        <!--config. notifications-->
        <div class="card-preferences-switch">
          <!--notifications-->
          <div class="preferences-switch-item">
            <b-field>
              <span class="switch-item-text">
                {{ $t(`${translationsPrefix}.userProfileModal.enabledNotifications`) }}
              </span>
              <b-switch v-model="activeNotifications"
                        size="is-medium"
              />
            </b-field>
          </div>
          <div class="preferences-switch-item">
            <!--email notifications-->
            <b-field>
              <span class="switch-item-text">
                {{ $t(`${translationsPrefix}.userProfileModal.emailNotifications`) }}
              </span>
              <b-switch v-model="activeEmailNotifications"
                        size="is-medium"
              />
            </b-field>
          </div>
        </div>
      </div>
      <!--card chage password-->
      <div class="card-content card-pass-change">
        <header class="card-sub-header">
          <p class="card-header-title subtitle">
            {{ $t(`${translationsPrefix}.userProfileModal.changePassword`) }}
          </p>
        </header>
        <ValidationObserver ref="observer" v-slot="{invalid}">
          <!--      fields new password-->
          <div class="columns card-pass-change-items">
            <!--current password-->
            <ValidationProvider v-slot="{errors}"
                                class="column is-one-third"
                                :rules="{required: true, min: 8, max: 50}"
            >
              <b-field
                :label="$t(`${translationsPrefix}.userProfileModal.oldPassword`)"
                :type="{'is-danger': errors.length}"
                :message="errors"
                custom-class="required"
              >
                <b-input v-model="oldPassword"
                         name="new_password"
                         type="password"
                />
              </b-field>
            </ValidationProvider>
            <!-- eslint-disable vue/camelcase -->
            <!--new password-->
            <ValidationProvider v-slot="{errors, failedRules}"
                                class="column is-one-third"
                                :rules="{required: true, min: 8, max: 50, is_not: oldPassword}"
            >
              <!-- eslint-enable vue/camelcase -->

              <b-field :label="$t(`${translationsPrefix}.userProfileModal.newPassword`)"
                       :type="{'is-danger': errors.length}"
                       :message="newPasswordMessage(failedRules, errors)"
                       custom-class="required"
              >
                <b-input v-model="newPassword"
                         name="new_password"
                         type="password"
                />
              </b-field>
            </ValidationProvider>
            <!--confirm new password-->
            <ValidationProvider v-slot="{errors, failedRules}"
                                class="column is-one-third"
                                :rules="{required: true, is: newPassword}"
            >
              <b-field :label="$t(`${translationsPrefix}.userProfileModal.confirmPassword`)"
                       :type="{'is-danger': errors.length}"
                       :message="confirmPasswordMessage(failedRules, errors)"
                       custom-class="required"
              >
                <b-input v-model="newPasswordCopy"
                         name="confirmPassword"
                         type="password"
                />
              </b-field>
            </ValidationProvider>
          </div>
          <div class="card-footer">
            <div class="card-actions">
              <div class="card-footer-item">
                <b-button type="is-primary" :disabled="invalid" @click="changePassword">
                  {{ $t(`${translationsPrefix}.userProfileModal.changePassword`) }}
                </b-button>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </form>
</template>
<script>
import Translations from '@/mixins/Translations'

export default {
  name: 'UserProfileModal',
  mixins: [Translations],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newPassword: '',
      newPasswordCopy: '',
      oldPassword: '',
    }
  },
  computed: {
    activeNotifications: {
      get () {
        return this.user.active_notifications ?? false
      },
      set () {
        this.$emit('change-active-notifications')
      },
    },
    activeEmailNotifications: {
      get () {
        return this.user.active_email_notifications ?? false
      },
      set () {
        this.$emit('change-active-email-notifications')
      },
    },
  },
  methods: {
    async changePassword() {
      const passwords = {
        new_password: this.newPassword,
        new_password_copy: this.newPasswordCopy,
        old_password: this.oldPassword,
      }
      this.$emit('change-password', passwords)
      this.resetPasswords()
    },
    getFirstLetter() {
      return this.user.name.charAt(0).toUpperCase()
    },
    confirmPasswordMessage(failedRules, errors) {
      // Messages from lowest to highest priority
      if (Object.prototype.hasOwnProperty.call(failedRules, 'is')) {
        return [this.$t(`${this.translationsPrefix}.userProfileModal.msgPasswordsNotMatching`)]
      } else {
        return errors
      }
    },
    newPasswordMessage(failedRules, errors) {
      // Messages from lowest to highest priority
      if (Object.prototype.hasOwnProperty.call(failedRules, 'is_not')) {
        return [this.$t(`${this.translationsPrefix}.userProfileModal.msgPasswordsEquals`)]
      } else {
        return errors
      }
    },
    resetPasswords() {
      this.newPassword = ''
      this.newPasswordCopy = ''
      this.oldPassword = ''

      // Limpiamos los errores del formulario
      this.$refs.observer.reset()
    },
  },
}
</script>
