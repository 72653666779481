<template>
  <div v-if="!isLoading" class="login login-admin">
    <LoginComponent :axios="axios"
                    :redirect="redirect"
                    :translations-prefix="translationsPrefix"
                    @login="fetchUser"
                    @recover-password="recoverPassword($event)"
    />
  </div>
</template>

<script>
import adminAxios from '@services/adminAxios'
import UsersService from '@services/UsersService'
import LoginComponent from '@components/Login'
import { Actions } from '@store/admin/types/actions'
import { Getters } from '@store/admin/types/getters'
import { mapActions, mapGetters } from 'vuex'
import Notifications from '@/mixins/Notifications'

export default {
  name: 'Login',
  components: {
    LoginComponent,
  },
  mixins: [Notifications],
  props: {
    redirect: {
      type: String,
      default: '/',
    },
  },
  data () {
    return {
      isLoading: false,
      axios: null,
      actions: null,
    }
  },
  computed: {
    ...mapGetters({
      translationsPrefix: Getters.GET_TRANSLATIONS_PREFIX,
    }),
  },
  created () {
    this.init()
  },
  methods: {
    ...mapActions({
      fetchUser: Actions.FETCH_USER,
    }),
    init () {
      this.isLoading = true
      this.axios = adminAxios
      this.actions = Actions
      this.isLoading = false
    },
    recoverPassword (email) {
      UsersService.askRecoveryPassword(email).then(() => {
        this.showInfo(
          'is-success',
          this.$t(`${this.translationsPrefix}.forgottenPasswordModal.correctForgottenPasswordMessage`)
        )
      }).catch (() => {
        this.showInfo(
          'is-danger',
          this.$t(`${this.translationsPrefix}.forgottenPasswordModal.incorrectForgottenPasswordMessage`)
        )
      })
    },
  },
}
</script>
