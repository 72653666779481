<template>
  <SubHeader :is-loaded="isLoaded" :structure="formStructure" />
</template>
<script>
import SubHeader from '@/components/SubHeader.vue'
import SubHeaderMixin from '@/mixins/SubHeader'
import UsersService from '@services/UsersService'
import { Getters } from '@store/admin/types/getters'
import { mapGetters } from 'vuex'

export default {
  name: 'TechFormSubHeader',
  components: {
    SubHeader,
  },
  mixins: [SubHeaderMixin],
  props: {
    idTech: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      tech: {},
      isLoaded: true,
      backUrl: '/tecnicos',
    }
  },
  computed: {
    ...mapGetters({
      translationsPrefix: Getters.GET_TRANSLATIONS_PREFIX,
    }),
    segments () {
      const segments = [
        {
          titleClass: 'text-info',
          valueClass: 'title-med',
          title: this.$t('admin.techFormSubHeader.techTitle'),
          value: this.tech.name,
        },
        {
          titleClass: 'text-info',
          valueClass: 'subtitle',
          title: this.$t('admin.techFormSubHeader.dateTitle'),
          value: new Date(this.tech.created_at).toLocaleDateString(),
        },
      ]
      return segments
    },
  },
  async created () {
    if (this.idTech) {
      this.isLoaded = false
      await UsersService.showUser(this.idTech).then(
        response => {
          this.tech = response
        }
      )
      this.isLoaded = true
    }
  },
}
</script>
