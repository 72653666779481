<template>
  <div class="login login-new-pass" @keyup.enter="changePassword">
    <div class="login-inner">
      <div class="card card-login card-new-pass">
        <ValidationObserver v-slot="{invalid}">
          <div class="card-header">
            <div class="logo-color" />
            <img src="@assets/logos/o-logo-color.svg" alt="logo">
          </div>
          <div class="card-content">
            <!--new password-->
            <div class="field">
              <ValidationProvider v-slot="{errors}" :rules="{required: true, min: 8, max: 50}">
                <b-field :label="$t(`${translationsPrefix}.newPasswordForgotten.newPassword`)"
                         :type="{'is-danger': errors.length}"
                         :message="errors"
                         custom-class="required"
                >
                  <b-input v-model="user.new_password"
                           name="new_password"
                           type="password"
                           icon-pack="icon-custom"
                           icon="lock lock-grey-lighter"
                           :placeholder="$t(`${translationsPrefix}.newPasswordForgotten.introPassword`)"
                  />
                </b-field>
              </ValidationProvider>
            </div>
            <!--new password confirm-->
            <div class="field">
              <ValidationProvider v-slot="{errors, failedRules}" :rules="{required: true, is: user.new_password}">
                <b-field :label="$t(`${translationsPrefix}.newPasswordForgotten.confirmPassword`)"
                         :type="{'is-danger': errors.length}"
                         :message="confirmPasswordMessage(failedRules)"
                         custom-class="required"
                >
                  <b-input v-model="user.new_password_copy"
                           name="confirmPassword"
                           type="password"
                           icon-pack="icon-custom"
                           icon="lock lock-grey-lighter"
                           :placeholder="$t(`${translationsPrefix}.newPasswordForgotten.introPassword`)"
                  />
                </b-field>
              </ValidationProvider>
            </div>
          </div>
          <div class="card-footer">
            <div class="card-actions is-paddingless">
              <div class="card-footer-item">
                <b-button type="is-primary"
                          class="is-fullwidth"
                          :class="{'is-loading': isLoading}"
                          :disabled="invalid"
                          @click="changePassword"
                >
                  <span>
                    {{ $t(`${translationsPrefix}.newPasswordForgotten.save`) }}
                  </span>
                </b-button>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import Translations from '@/mixins/Translations'

export default {
  name: 'NewPasswordForgotten',
  mixins: [Translations],
  props: {
    recoverPassword: {
      type: Function,
      default: () => {},
    },
  },
  data () {
    return {
      user: {
        new_password: '',
        new_password_copy: '',
        token_temp: this.$route.query.recoveryToken,
      },
      isLoading: false,
    }
  },
  methods: {
    confirmPasswordMessage (failedRules) {
      let message = null

      // Messages from lowest to highest priority
      if (Object.prototype.hasOwnProperty.call(failedRules, 'is')) {
        message = this.$t(`${this.translationsPrefix}.newPasswordForgotten.msgPasswordsNotMatching`)
      }

      if (Object.prototype.hasOwnProperty.call(failedRules, 'required')) {
        message = this.$t(`${this.translationsPrefix}.newPasswordForgotten.msgMandatoryConfirmPassword`)
      }

      return [message]
    },
    async changePassword () {
      this.$emit('change-password', this.user)
    },
  },
}
</script>
