const Actions = {
  FETCH_USER: 'fetchUser',
  SET_NUMBER_INCIDENTS: 'setNumberIncidents',
  SET_NUMBER_DOCUMENTS: 'setNumberDocuments',
  SET_ORDER_STATUS: 'setOrderStatus',
  FETCH_NOTIFICATIONS: 'fetchNotifications',
  CHANGE_READ_VALUE_NOTIFICATION: 'changeReadValueNotification',
  SET_LANGUAGE: 'setLanguage',
}

// const {Modulename}Actions = { }

export { Actions /* {Modulename}Actions */ }
