<template>
  <SubHeader :is-loaded="isLoaded" :structure="formStructure" />
</template>
<script>
import SubHeader from '@/components/SubHeader.vue'
import SubHeaderMixin from '@/mixins/SubHeader'
import OrdersService from '@services/OrdersService'
import { DocumentStatus } from '@/enums/DocumentStatus'
import { mapGetters } from 'vuex'
import { Getters } from '@store/suppliers/types/getters'

export default {
  name: 'OrderFormSubHeader',
  components: {
    SubHeader,
  },
  mixins: [SubHeaderMixin],
  props: {
    numOrder: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      isLoaded: true,
      order: {},
      backUrl: '/pedidos',
    }
  },
  computed: {
    ...mapGetters({
      translationsPrefix: Getters.GET_TRANSLATIONS_PREFIX,
      numIncidents: Getters.GET_NUMBER_INCIDENTS,
      orderStatus: Getters.GET_ORDER_STATUS,
    }),
    segments () {
      const segments = [
        {
          titleClass: 'text-info',
          valueClass: 'title-med',
          title: this.$t('suppliers.orderFormSubHeader.numDocumentTitle'),
          value: this.order.num_document,
        },
        {
          titleClass: 'text-info',
          valueClass: 'subtitle',
          title: this.$t('suppliers.orderFormSubHeader.dateTitle'),
          value: new Date(this.order.date).toLocaleDateString(),
        },
        {
          titleClass: 'text-info',
          valueClass: this.statusClass,
          title: this.$t('suppliers.orderFormSubHeader.statusTitle'),
          value: this.$t(`suppliers.statusList.${this.orderStatus}`),
          additionalInformation: this.displayedNumIncidents,
          additionalInformationClass: 'status-count',
        },
      ]
      return segments
    },
    statusClass () {
      return this.orderStatus ?
        'status status-' + this.orderStatus.toLowerCase().replaceAll('.', '').replaceAll(/\s/g, '-') : ''
    },
    displayedNumIncidents () {
      return this.orderStatus === DocumentStatus.WithIncidents ?
        this.numIncidents : null
    },
  },
  created () {
    if (this.numOrder) {
      this.isLoaded = false
      OrdersService.showOrder(this.numOrder).then(response => {
        this.order = response.data
        this.isLoaded = true
      })
    }
  },
}
</script>
