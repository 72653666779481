<template>
  <div class="card-notifications-content">
    <div class="card-header">
      <b-button type="is-text"
                class="is-fullwidth-mobile"
                :disabled="!notifications.length"
                @click="markAllNotificationsAsRead"
      >
        <span class="is-medium">
          {{ $t(`${translationsPrefix}.newNotifications.markAsRead`) }}
        </span>
      </b-button>
    </div>
    <div class="card-content">
      <b-table :data="notifications"
               :loading="loading"
               :backend-sorting="false"
               :default-sort-direction="defaultSortDirection"
               :default-sort="defaultSortField"
               sticky-header
               class="table is-striped table-list-control"
      >
        <b-table-column v-slot="props"
                        field="read"
                        :label="$t(`${translationsPrefix}.newNotifications.read`)"
                        cell-class="table-data"
                        header-class="table-title"
        >
          <b-checkbox :native-value="props.row.read"
                      @input="changeReadValueNotification(props.row.id)"
          />
        </b-table-column>
        <b-table-column v-slot="props"
                        field="num_order"
                        :label="$t(`${translationsPrefix}.newNotifications.numOrder`)"
                        sortable
                        cell-class="table-data"
                        header-class="table-title"
        >
          <span class="button-underline" @click="goToOrderPage(props.row.num_order, props.row.supplier_id)">
            {{ props.row.num_order }}
          </span>
        </b-table-column>
        <b-table-column v-slot="props"
                        field="date"
                        :label="$t(`${translationsPrefix}.newNotifications.date`)"
                        sortable
                        cell-class="table-data"
                        header-class="table-title"
        >
          {{ dayjs(props.row.date).format('DD/MM/YYYY HH:mm:ss') }}
        </b-table-column>
        <b-table-column v-slot="props"
                        field="status"
                        :label="$t(`${translationsPrefix}.newNotifications.status`)"
                        sortable
                        cell-class="table-data"
                        header-class="table-title"
        >
          {{ $t(`${translationsPrefix}.statusList.${props.row.status}`) }}
        </b-table-column>
        <template #empty>
          <div class="has-text-centered">
            {{ $t(`${translationsPrefix}.newNotifications.noData`) }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import Translations from '@/mixins/Translations'

export default {
  name: 'NewNotifications',
  mixins: [Translations],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      dayjs,
      defaultSortDirection: 'desc',
      defaultSortField: 'date',
      loading: false,
    }
  },
  computed: {
    notifications () {
      return this.value ?? []
    },
  },
  methods: {
    markAllNotificationsAsRead () {
      this.$emit('mark-unread-notifications-as-read')
    },
    changeReadValueNotification (id) {
      this.$emit('change-read-value-notification', id)
    },
    goToOrderPage (numOrder, idSupplier) {
      this.$emit('go-to-order-page', { numOrder, idSupplier })
    },
  },
}
</script>
