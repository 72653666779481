<template>
  <div>
    <HeaderComponent :user="user"
                     :axios="axios"
                     :translations-prefix="translationsPrefix"
                     @change-password="changePassword($event)"
                     @change-active-notifications="changeActiveNotifications"
                     @change-active-email-notifications="changeActiveEmailNotifications"
                     @mark-unread-notifications-as-read="markUnreadNotificationsAsRead"
                     @mark-checked-notifications="markCheckedNotifications"
                     @change-read-value-notification="changeReadValueNotification($event)"
                     @go-to-order-page="goToOrderPage($event)"
    >
      <template slot="languages">
        <b-field>
          <b-select v-model="selectedLanguage"
                    class="select"
          >
            <option v-for="(language, index) in Languages" :key="index" :value="language">
              {{ $t(`${translationsPrefix}.languages.${language}`) }}
            </option>
          </b-select>
        </b-field>
      </template>
    </HeaderComponent>
  </div>
</template>

<script>
import supplierAxios from '@services/supplierAxios'
import UsersSuppliersService from '@services/UsersSuppliersService'
import HeaderComponent from '@components/Header'
import { Getters } from '@store/suppliers/types/getters'
import { Actions } from '@store/suppliers/types/actions'
import { mapGetters, mapActions } from 'vuex'
import Notifications from '@/mixins/Notifications'
import { Languages } from '@/enums/Languages'

export default {
  name: 'Header',
  components: {
    HeaderComponent,
  },
  mixins: [Notifications],
  props: {
    redirect: {
      type: String,
      default: '/',
    },
  },
  data () {
    return {
      Languages,
      isLoading: false,
      axios: null,
    }
  },
  computed: {
    ...mapGetters({
      user: Getters.GET_USER,
      translationsPrefix: Getters.GET_TRANSLATIONS_PREFIX,
      language: Getters.GET_LANGUAGE,
    }),
    notifications () {
      return this.user ? this.user.notifications : []
    },
    readNotifications () {
      return this.notifications.filter(notification => notification.read)
    },
    selectedLanguage: {
      get () {
        return this.language
      },
      set (value) {
        this.$i18n.locale = value
        this.changeLanguage(value)
      },
    },
  },
  created () {
    this.init()
  },
  methods: {
    ...mapActions({
      fetchUser: Actions.FETCH_USER,
      fetchNotifications: Actions.FETCH_NOTIFICATIONS,
      changeReadValueNotification: Actions.CHANGE_READ_VALUE_NOTIFICATION,
      changeLanguage: Actions.SET_LANGUAGE,
    }),
    async init () {
      this.isLoading = true
      this.axios = supplierAxios
      if (!this.user) {
        await this.fetchUser()
      }
      this.isLoading = false
    },
    changePassword (passwords) {
      UsersSuppliersService.changePassword(this.user.id, passwords)
        .then(
          () => {
            this.showInfo('is-success', this.$t(`${this.translationsPrefix}.header.correctChangePasswordMsg`))
          })
        .catch(() => {
          this.showInfo(
            'is-danger', this.$t(`${this.translationsPrefix}.header.errorChangePasswordMsg`))
        })
    },
    async changeActiveNotifications () {
      await UsersSuppliersService.changeActiveNotifications(this.user.id)
      await this.fetchUser()
    },
    async changeActiveEmailNotifications () {
      await UsersSuppliersService.changeActiveEmailNotifications(this.user.id)
      await this.fetchUser()
    },
    async markUnreadNotificationsAsRead () {
      await UsersSuppliersService.markUnreadNotificationsAsRead()
      await this.fetchNotifications()
    },
    async markCheckedNotifications () {
      for (const notification of this.readNotifications) {
        await this.markNotificationAsRead(notification.id)
      }
      if (this.readNotifications.length > 0) {
        await this.fetchNotifications()
      }
    },
    async markNotificationAsRead (id) {
      await UsersSuppliersService.markNotificationAsRead(id)
    },
    goToOrderPage ({ numOrder }) {
      this.$router.push(`/pedidos/${numOrder}`)
    },
  },
}
</script>
