export default {
  methods: {
    showInfo (type, message) {
      this.$buefy.snackbar.open({ type, message })
    },
    deleteDialog (dataDeleting) {
      const aditionalData = dataDeleting.aditionalData ?? {}
      this.$buefy.dialog.confirm({
        title: dataDeleting.title,
        message: dataDeleting.message,
        confirmText: this.$t(`${this.translationsPrefix}.notifications.confirmTextDialog`),
        cancelText: this.$t(`${this.translationsPrefix}.notifications.cancelTextDialog`),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => dataDeleting.onConfirmFunction(dataDeleting.id, aditionalData),
      })
    },
  },
}
