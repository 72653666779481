<template>
  <div class="field is-marginless">
    <ValidationProvider v-slot="{errors}" :rules="{required: required}">
      <b-field :label="title"
               :type="{'is-danger': errors.length}"
               :message="errors"
               custom-class="required"
      >
        <b-taginput ref="taginput"
                    v-model="selectedTags"
                    class="field-tag-input"
                    field="name"
                    :data="validTags"
                    autocomplete
                    open-on-focus
                    :tabstop="false"
                    :placeholder="placeholder"
                    ellipsis
                    :disabled="disabled"
                    :maxtags="getMaxTags()"
                    :has-counter="false"
                    dropdown-position="bottom"
                    @add="filterText = ''"
                    @typing="filterText = $event"
        >
          <template slot-scope="tagProps">
            {{ tagProps.option.tagName }}
          </template>
          <template slot="empty">
            {{ emptyMessage }}
          </template>
          <template slot="selected" slot-scope="propsTags">
            <b-tag v-for="(tag, index) in propsTags.tags"
                   :key="index"
                   closable
                   @close="removeProperty(index, $event)"
            >
              {{ tag.tagName }}
            </b-tag>
          </template>
        </b-taginput>
      </b-field>
    </ValidationProvider>
  </div>
</template>

<script>
import Translations from '@/mixins/Translations'

export default {
  name: 'TagInput',
  mixins: [Translations],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    tagsArray: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    emptyMessage: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
    },
    maxTags: {
      type: Number,
      default: null,
    },
    required: {
      type: Boolean,
    },
  },
  data () {
    return {
      filterText: '',
    }
  },
  computed: {
    selectedTags: {
      get () {
        return this.value ?? []
      },
      set (value) {
        this.$emit('input', value)
      },
    },
    validTagsWithoutFilter () {
      return this.tagsArray
        .filter(tag => {
          const alreadyAdded = this.selectedTags.find(selectedTag => tag.tagName === selectedTag.tagName)
          return !alreadyAdded
        })
    },
    validTags () {
      return this.filterText.length ? this.validTagsWithoutFilter.filter(tag =>
        tag.tagName
          .toString()
          .toLowerCase()
          .indexOf(this.filterText.toLowerCase()) >= 0
      ) : this.validTagsWithoutFilter
    },
  },
  methods: {
    removeProperty (index, event) {
      this.$refs.taginput.removeTag(index, event)
    },
    setFilterText (text) {
      this.filterText = text
    },
    getMaxTags () {
      return this.maxTags ? this.maxTags : this.tagsArray.length
    },
    getErrorMessage () {
      return this.required ? this.$t(`${this.translationsPrefix}.validation.required`) : ''
    },
  },
}
</script>
