<template>
  <div>
    <HeaderComponent :user="user"
                     :axios="axios"
                     :translations-prefix="translationsPrefix"
                     @change-password="changePassword($event)"
                     @change-active-notifications="changeActiveNotifications"
                     @change-active-email-notifications="changeActiveEmailNotifications"
                     @mark-unread-notifications-as-read="markUnreadNotificationsAsRead"
                     @mark-checked-notifications="markCheckedNotifications"
                     @change-read-value-notification="changeReadValueNotification($event)"
                     @go-to-order-page="impersonate($event)"
    />
  </div>
</template>

<script>
import adminAxios from '@services/adminAxios'
import UsersService from '@services/UsersService'
import HeaderComponent from '@components/Header'
import { Getters } from '@store/admin/types/getters'
import { Actions } from '@store/admin/types/actions'
import { mapGetters, mapActions } from 'vuex'
import Notifications from '@/mixins/Notifications'
import SuppliersService from '@services/SuppliersService'

export default {
  name: 'Header',
  components: {
    HeaderComponent,
  },
  mixins: [Notifications],
  props: {
    redirect: {
      type: String,
      default: '/',
    },
  },
  data () {
    return {
      isLoading: false,
      axios: null,
    }
  },
  computed: {
    ...mapGetters({
      user: Getters.GET_USER,
      translationsPrefix: Getters.GET_TRANSLATIONS_PREFIX,
    }),
    notifications () {
      return this.user.notifications
    },
    readNotifications () {
      return this.notifications.filter(notification => notification.read)
    },
  },
  created () {
    this.init()
  },
  methods: {
    ...mapActions({
      fetchUser: Actions.FETCH_USER,
      fetchNotifications: Actions.FETCH_NOTIFICATIONS,
      changeReadValueNotification: Actions.CHANGE_READ_VALUE_NOTIFICATION,
    }),
    async init () {
      this.isLoading = true
      this.axios = adminAxios
      if (!this.user.id) {
        await this.fetchUser()
      }
      this.isLoading = false
    },
    changePassword (passwords) {
      UsersService.changePassword(this.user.id, passwords)
        .then(
          () => {
            this.showInfo('is-success', this.$t(`${this.translationsPrefix}.header.correctChangePasswordMsg`))
          })
        .catch(() => {
          this.showInfo(
            'is-danger', this.$t(`${this.translationsPrefix}.header.errorChangePasswordMsg`))
        })
    },
    async changeActiveNotifications () {
      await UsersService.changeActiveNotifications(this.user.id)
      await this.fetchUser()
    },
    async changeActiveEmailNotifications () {
      await UsersService.changeActiveEmailNotifications(this.user.id)
      await this.fetchUser()
    },
    async markUnreadNotificationsAsRead () {
      await UsersService.markUnreadNotificationsAsRead()
      await this.fetchNotifications()
    },
    async markCheckedNotifications () {
      for (const notification of this.readNotifications) {
        await this.markNotificationAsRead(notification.id)
      }
      if (this.readNotifications.length > 0) {
        await this.fetchNotifications()
      }
    },
    async markNotificationAsRead (id) {
      await UsersService.markNotificationAsRead(id)
    },
    async impersonate ({ numOrder, idSupplier }) {
      this.token = await SuppliersService.impersonate(idSupplier)
      /* eslint-disable no-undef */
      const protocol = process.env.MIX_APP_SUPPLIERS_PROTOCOL
      const domain = process.env.MIX_APP_SUPPLIERS_DOMAIN
      const prefix = process.env.MIX_APP_SUPPLIERS_PREFIX
      const prefixUrl = prefix ? `${prefix}/` : ''
      const suppliersUrl = `${protocol}://${domain}/${prefixUrl}pedidos/${numOrder}?token=${this.token}`
      /* eslint-enable no-undef */
      window.open(suppliersUrl, '_blank')
    },
  },
}
</script>
