import axios from 'axios'
import Fingerprint2 from 'fingerprintjs2'

let refreshing = false

const LaravelPassportApiAxios = (apiUrl, options, refreshTokenException, genericExceptionCallback) => {
  let previousInterceptor = null
  const paths = options.paths
  const access_token = options.access_token_name || 'access_token'
  const refresh_token = options.refresh_token_name || 'refresh_token'

  const apiAxios = axios.create({
    baseURL: apiUrl,
  })

  if (localStorage[access_token]) {
    setAuthorizationHeader(localStorage[access_token])
  }

  function onResponseOk (responseOk) {
    if (responseOk.config.responseType !== 'arraybuffer' && responseOk.config.responseType !== 'blob') {
      return responseOk.data
    }

    return responseOk
  }

  function onPassportResponseError (responseError) {
    return Promise.reject(responseError)
  }

  function onApiResponseError (responseError) {
    const response = responseError.response

    if (response && response.status === 401) {
      return refreshToken(responseError).then(repeatRequest)
    }

    if (genericExceptionCallback) {
      return genericExceptionCallback(responseError)
    }

    return Promise.reject(responseError)
  }

  function removeLocalStorage () {
    localStorage.removeItem(access_token)
    localStorage.removeItem(refresh_token)
    setAuthorizationHeader('')
  }

  setApiResponseHandlers()

  apiAxios.login = async user => {
    setPassportResponseHandlers()
    try {
      user.fingerprint = await apiAxios.fingerprintGenerate()
      const response = await apiAxios.post(`/${paths.login || 'login'}`, user)
      localStorage[access_token] = response.access_token
      localStorage[refresh_token] = response.refresh_token
      setAuthorizationHeader(localStorage[access_token])

      return response
    } finally {
      setApiResponseHandlers()
    }
  }

  apiAxios.logout = async user => {
    const response = await apiAxios.post(`/${paths.logout || 'login'}`, user)
    removeLocalStorage()

    return response
  }

  apiAxios.loggedIn = () => localStorage.getItem(access_token) !== null

  apiAxios.fingerprintGenerate = async () => {
    const components = await Fingerprint2.getPromise()

    // Eliminar parámetros referentes a la resolución de pantalla
    const selectedComponents = components.filter((value, index, arr) =>
      (arr[index].key !== 'screenResolution' && arr[index].key !== 'availableScreenResolution')
    )
    const values = selectedComponents.map(component => component.value)
    return Fingerprint2.x64hash128(values.join(''), 31)
  }

  function setAuthorizationHeader (token) {
    apiAxios.defaults.headers.common.Authorization = `Bearer ${token}`
  }

  function setApiResponseHandlers () {
    removePreviousInterceptor()
    previousInterceptor = apiAxios.interceptors.response.use(onResponseOk, onApiResponseError)
  }

  function setPassportResponseHandlers () {
    removePreviousInterceptor()
    previousInterceptor = apiAxios.interceptors.response.use(onResponseOk, onPassportResponseError)
  }

  function removePreviousInterceptor () {
    if (previousInterceptor !== null) {
      apiAxios.interceptors.response.eject(previousInterceptor)
    }
  }

  async function refreshToken (responseError) {
    if (refreshing) {
      // Concurrent token's refreshes not allowed
      return Promise.resolve(responseError)
    }

    refreshing = true

    const hash = await apiAxios.fingerprintGenerate()

    return refreshTokenRequest(hash, responseError)
  }

  function refreshTokenRequest (hash, responseError) {
    return new Promise(function (resolve, reject) {
      setPassportResponseHandlers()
      const path = `/${paths.refresh || 'refreshToken'}`
      apiAxios.post(path, { refresh_token: localStorage[refresh_token], fingerprint: hash })
        .then(response => {
          localStorage[access_token] = response.access_token
          localStorage[refresh_token] = response.refresh_token
          setAuthorizationHeader(response.access_token)
          resolve(responseError)
        })
        .catch(error => {
          removeLocalStorage()
          reject(Object.assign(error, refreshTokenException))
        })
        .finally(() => {
          refreshing = false
          setApiResponseHandlers()
        })
    })
  }

  function repeatRequest (responseError) {
    return new Promise((resolve, reject) => {
      // Wait to refreshed token before repeat
      const intervalId = setInterval(() => {
        if (!refreshing) {
          clearInterval(intervalId)
          // When refresh fail, login is lost. Abort repeat
          if (!apiAxios.loggedIn()) {
            reject(responseError)
            return
          }
          sendRepeatRequest(responseError).then(response => resolve(response))
        }
      }, 50)
    })
  }

  function sendRepeatRequest (responseError) {
    const originalRequest = responseError.config
    originalRequest.headers.Authorization = `Bearer ${localStorage[access_token]}`
    return apiAxios(originalRequest)
  }

  return apiAxios
}

export default LaravelPassportApiAxios
