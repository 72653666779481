<template>
  <div v-if="!loadingData" class="card card-tech-form">
    <!--card header-->
    <div class="card-header">
      <p class="card-header-title">
        {{ getTextHeader() }}
      </p>
      <slot>
        <b-button class="button-square"
                  type="is-text"
                  icon-pack="icon-custom"
                  icon-left="icon-trash icon-trash-primary"
                  :disabled="deleteButtonDisabled"
                  @click="deleteTech"
        />
      </slot>
    </div>
    <!--card content-->
    <ValidationObserver ref="observer" v-slot="{invalid}">
      <div class="card-content">
        <div class="columns">
          <!--name column-->
          <div class="column is-full">
            <ValidationProvider v-slot="{errors}" :rules="{required: true}">
              <b-field :label="$t('admin.techForm.name')"
                       :type="{'is-danger': errors.length}"
                       :message="errors"
              >
                <b-input v-model="tech.name"
                         name="name"
                />
              </b-field>
            </ValidationProvider>
          </div>
        </div>
        <div class="columns">
          <!--email column-->
          <div class="column is-half">
            <ValidationProvider v-slot="{errors}" :rules="{required: true, email: true}">
              <b-field :label="$t('admin.techForm.email')"
                       :type="{'is-danger': errors.length}"
                       :message="errors"
              >
                <b-input v-model="tech.email"
                         name="email"
                />
              </b-field>
            </ValidationProvider>
          </div>
          <!--location column-->
          <div class="column is-half">
            <b-field :label="$t('admin.techForm.location')">
              <b-input v-model="tech.location"
                       name="location"
              />
            </b-field>
          </div>
        </div>
        <div class="columns">
          <!--department column-->
          <div class="column is-half">
            <b-field :label="$t('admin.techForm.department')">
              <b-input v-model="tech.department"
                       name="department"
              />
            </b-field>
          </div>
          <!--role column-->
          <div class="column is-half">
            <ValidationProvider v-slot="{errors}" :rules="{required: true}">
              <b-field :label="$t('admin.techForm.roles.title')"
                       :type="{'is-danger': errors.length}"
                       :message="errors"
              >
                <b-select v-model="tech.role_id"
                          :placeholder="$t('admin.techForm.roles.placeholder')"
                          class="select"
                >
                  <option v-for="(role, index) in UserRole" :key="index" :value="role.id">
                    {{ $t(`admin.techForm.roles.${role.name}`) }}
                  </option>
                </b-select>
              </b-field>
            </ValidationProvider>
          </div>
        </div>


        <div v-for="user in tech.users_techs" :key="user.id">
          <ValidationProvider v-slot="{errors}" :rules="{required: true, min: 8, max: 50}">
            <b-field :label="$t('admin.techForm.name')"
                     :type="{'is-danger': errors.length}"
                     :message="errors"
            >
              <b-input v-model="user.name"
                       name="name"
              />
            </b-field>
          </ValidationProvider>
          <ValidationProvider v-slot="{errors}" :rules="{required: true, min: 8, max: 50}">
            <b-field :label="$t('admin.techForm.email')"
                     :type="{'is-danger': errors.length}"
                     :message="errors"
            >
              <b-input v-model="user.email"
                       name="email"
              />
            </b-field>
          </ValidationProvider>
        </div>
      </div>
      <footer class="card-footer">
        <!-- ACTION BUTTONS -->
        <div class="card-actions is-paddingless">
          <div class="card-footer-item">
            <b-button type="is-primary" class="is-fullwidth" :disabled="invalid" @click="getActionButton">
              {{ getTextButton() }}
            </b-button>
          </div>
        </div>
      </footer>
      <b-loading :is-full-page="isFullPage" :active.sync="loadingData" :can-cancel="true">
        <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin" />
      </b-loading>
    </ValidationObserver>
  </div>
</template>

<script>
import UsersService from '@services/UsersService'
import Notifications from '@/mixins/Notifications'
import { Getters } from '@store/admin/types/getters'
import { mapGetters } from 'vuex'
import { UserRole } from '@/enums/UserRole'

export default {
  name: 'TechForm',
  mixins: [Notifications],
  props: {
    idTech: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      UserRole,
      loadingData: false,
      isFullPage: false,
      tech: {},
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: Getters.GET_USER,
      translationsPrefix: Getters.GET_TRANSLATIONS_PREFIX,
    }),
    deleteButtonDisabled () {
      return this.loggedUser.id === this.tech.id
    },
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      if (this.idTech) {
        this.loadingData = true
        await this.getTech()
        this.loadingData = false
      }
    },
    async getTech () {
      this.tech = await UsersService.showUser(this.idTech)
    },
    getTextHeader () {
      return this.idTech ? this.$t('admin.techForm.infoGeneral') : this.$t('admin.techForm.createTech')
    },
    getTextButton () {
      return this.idTech ? this.$t('admin.techForm.save') : this.$t('admin.techForm.add')
    },
    getActionButton () {
      return this.idTech ? this.updateTech() : this.createTech()
    },
    createTech () {
      UsersService.createUser(this.tech)
        .then(
          () => {
            this.showInfo('is-success', this.$t('admin.techForm.msgCorrect'))
            this.$emit('created')
          })
        .catch(
          () => this.showInfo('is-danger', this.$t('admin.techForm.msgIncorrect'))
        )
    },
    updateTech () {
      this.loadingData = true
      UsersService.updateUser(this.idTech, this.tech)
        .then(
          () => {
            this.showInfo('is-success', this.$t('admin.techForm.msgUpdateCorrect'))
            this.loadingData = false
            this.$router.push('/tecnicos')
          })
        .catch(
          () => {
            this.showInfo('is-danger', this.$t('admin.techForm.msgUpdateIncorrect'))
            this.loadingData = false
          }
        )
    },
    confirmFunction (id) {
      UsersService.deleteUser(id)
        .then(() => {
          this.showInfo('is-success', this.$t('admin.techForm.msgCorrectDelete'))
          this.$router.push('/tecnicos')
        })
        .catch(() => this.showInfo('is-danger', this.$t('admin.techForm.msgIncorrectDelete')))
    },
    deleteTech () {
      const deleteDialog = {
        id: this.tech.id,
        title: this.$t('admin.techForm.titleDialog'),
        message: this.$t('admin.techForm.messageDialog', { name: this.tech.name }),
        onConfirmFunction: this.confirmFunction,
      }
      this.deleteDialog(deleteDialog)
    },
  },
}
</script>
