const DocumentStatus = {
  New: 'nuevo',
  InProgress: 'en-proceso',
  Pending: 'pendiente',
  Validated: 'validado',
  WithIncidents: 'con-incidencias',
  Incidence: 'incidencia',
  DocReq: 'doc-req',
  Invoiced: 'facturado',
}
export { DocumentStatus }
