<template>
  <ValidationObserver ref="observer" v-slot="{invalid}" class="card card-new-invoice card-modal">
    <!--CARD MODAL HEADER-->
    <header class="card-header">
      <p class="card-header-title">
        {{ $t('suppliers.newInvoiceModal.addInvoice') }}
      </p>
      <b-button class="button-icon button-icon-close" @click="$parent.close()">
        <em class="icon-custom icon-close icon-close-gray" />
      </b-button>
    </header>
    <!--CARD MODAL CONTENT BODY-->
    <div class="card-body card-body-scroll">
      <div class="card-content-multiple">
        <!--card information-->
        <div class="card-content card-new-invoice-info">
          <header class="card-sub-header">
            <p class="card-header-title subtitle">
              {{ $t('suppliers.newInvoiceModal.informationTitle') }}
            </p>
          </header>
          <div class="columns">
            <!--number invoice-->
            <div class="column is-one-third">
              <ValidationProvider v-slot="{errors}" :rules="{required: true}">
                <b-field :label="$t('suppliers.newInvoiceModal.numInvoice')"
                         :type="{'is-danger': errors.length}"
                         :message="errors"
                         custom-class="required"
                >
                  <b-input v-model="invoice.numInvoice"
                           required
                  />
                </b-field>
              </ValidationProvider>
            </div>
            <!--date invoice-->
            <div class="column is-one-third">
              <ValidationProvider v-slot="{errors}" :rules="{required: true}">
                <b-field :label="$t('suppliers.newInvoiceModal.date')"
                         :type="{'is-danger': errors.length}"
                         :message="errors"
                         custom-class="required"
                >
                  <b-datepicker v-model="invoice.date"
                                :placeholder="$t('suppliers.newInvoiceModal.datePlaceholder')"
                                position="is-bottom-left"
                                class="select"
                                custom-class="u-bg-grey"
                                required
                  />
                </b-field>
              </ValidationProvider>
            </div>
            <!--total invoice-->
            <div class="column is-one-third">
              <ValidationProvider v-slot="{errors}" :rules="{required: true}">
                <b-field :label="$t('suppliers.newInvoiceModal.totalAmount')"
                         :type="{'is-danger': errors.length}"
                         :message="errors"
                         custom-class="required"
                >
                  <b-input v-model="invoice.totalAmount"
                           required
                  />
                </b-field>
              </ValidationProvider>
            </div>
          </div>
          <div class="columns">
            <!--delivery number invoice-->
            <div class="column is-one-third">
              <TagInput v-model="invoice.numDeliveryNote"
                        :tags-array="deliveryNotesWithoutInvoice"
                        :title="$t('suppliers.newInvoiceModal.numDeliveryNote')"
                        :placeholder="$t('suppliers.newInvoiceModal.deliveryNotePlaceholder')"
                        :empty-message="$t('suppliers.newInvoiceModal.noDeliveryNotes')"
                        :max-tags="1"
                        custom-class="required"
                        :translations-prefix="translationsPrefix"
                        :required="true"
              />
            </div>
          </div>
        </div>

        <!--aditional order invoice-->
        <div v-if="false" class="card-content card-new-invoice-aditional">
          <header class="card-sub-header">
            <p class="card-header-title subtitle">
              {{ $t('suppliers.newInvoiceModal.aditionalOrders') }}
            </p>
          </header>
          <TagInput v-model="invoice.aditionalOrders"
                    :tags-array="aditionalOrders"
                    :title="$t('suppliers.newInvoiceModal.numAditionalOrders')"
                    :placeholder="$t('suppliers.newInvoiceModal.aditionalOrdersPlaceholder')"
                    :empty-message="$t('suppliers.newInvoiceModal.noAditionalOrders')"
                    :translations-prefix="translationsPrefix"
          />
        </div>
        <!--delivery notes invoice-->
        <div v-if="false" class="card-content card-new-invoice-notes-aditional">
          <header class="card-sub-header">
            <p class="card-header-title subtitle">
              {{ $t('suppliers.newInvoiceModal.aditionalDeliveryNotes') }}
            </p>
          </header>
          <TagInput v-model="invoice.aditionalDeliveryNotes"
                    :tags-array="aditionalDeliveryNotes"
                    :title="$t('suppliers.newInvoiceModal.numAditionalDeliveryNotes')"
                    :placeholder="$t('suppliers.newInvoiceModal.aditionalDeliveryNotesPlaceholder')"
                    :empty-message="$t('suppliers.newInvoiceModal.noAditionalDeliveryNotes')"
                    :translations-prefix="translationsPrefix"
          />
        </div>
        <!--card document upload-->
        <div class="card-content card-new-invoice-document">
          <ValidationProvider v-slot="{errors}" :rules="{required: true}">
            <b-field :label="$t('suppliers.newInvoiceModal.uploadFile')"
                     :message="errors || $t('suppliers.newInvoiceModal.validFiles')"
                     :type="{'is-danger': errors.length}"
                     custom-class="required"
            >
              <b-upload v-model="invoiceFile" class="file-label" accept=".pdf">
                <span class="file-cta button button-grey">
                  <span class="file-label">
                    {{ $t('suppliers.newInvoiceModal.selectFile') }}
                  </span>
                </span>
                <span v-if="invoiceFile" class="file-name">
                  <span class="file-name-copy">{{ invoiceFile.name }}</span>
                  <b-button class="button-icon button-icon-delete" @click="deleteFile">
                    <em class="icon-custom icon-trash icon-trash-gray" />
                  </b-button>
                </span>
                <span v-else class="file-name">
                  {{ $t('suppliers.newInvoiceModal.noFileSelected') }}
                </span>
              </b-upload>
            </b-field>
          </ValidationProvider>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="card-actions">
        <div class="card-footer-item">
          <b-button type="is-outline" class="is-fullwidth" @click="$parent.close()">
            {{ $t('suppliers.newInvoiceModal.cancelButton') }}
          </b-button>
        </div>
        <div class="card-footer-item">
          <b-button :disabled="invalid" type="is-primary" class="is-fullwidth" @click="uploadInvoice">
            {{ $t('suppliers.newInvoiceModal.addButton') }}
          </b-button>
        </div>
      </div>
    </div>
    <b-loading :is-full-page="isFullPage" :active.sync="loadingData" :can-cancel="true">
      <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin" />
    </b-loading>
  </ValidationObserver>
</template>
<script>
import TagInput from '@components/TagInput'
import OrdersService from '@services/OrdersService'
import _ from 'lodash'
import dayjs from 'dayjs'
import Notifications from '@/mixins/Notifications'
import { Getters } from '@store/suppliers/types/getters'
import { mapGetters } from 'vuex'

export default {
  name: 'NewInvoiceModal',
  components: {
    TagInput,
  },
  mixins: [Notifications],
  props: {
    numOrder: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      invoice: {
        numInvoice: '',
        date: null,
        totalAmount: '',
        numDeliveryNote: [],
        aditionalOrders: [],
        aditionalDeliveryNotes: [],
      },
      invoiceFile: null,
      aditionalOrders: ['PC210130', 'PC210201', 'PC210208', 'PC210216', 'PC210717'],
      aditionalDeliveryNotes: ['AL210130', 'AL210201', 'AL210208', 'AL210216', 'AL210717'],
      isFullPage: true,
      loadingData: false,
      deliveryNotes: [],
    }
  },
  computed: {
    ...mapGetters({
      translationsPrefix: Getters.GET_TRANSLATIONS_PREFIX,
    }),
    deliveryNotesWithoutInvoice () {
      return this.deliveryNotes
        .filter(deliveryNote => !deliveryNote.num_invoice)
        .map(deliveryNote => ({ tagName: deliveryNote.num_delivery_note }))
    },
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      this.getDeliveryNotes()
    },
    async getDeliveryNotes () {
      this.loading = true
      try {
        const results = await OrdersService.getDeliveryNotes()
        this.deliveryNotes = results.data
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    uploadInvoice () {
      this.loadingData = true
      const invoice = _.cloneDeep(this.invoice)
      invoice.date = invoice.date ? dayjs(invoice.date).format('YYYY-MM-DD') : null
      const selectedDeliveryNote = invoice.numDeliveryNote[0]
      const deliveryNote = this.deliveryNotes.find(
        deliveryNote => deliveryNote.num_delivery_note === selectedDeliveryNote.tagName
      )
      invoice.idDeliveryNote = deliveryNote.id
      invoice.numDeliveryNote = deliveryNote.num_delivery_note
      OrdersService.uploadInvoice(this.numOrder, invoice, this.invoiceFile).then(() =>{
        this.showInfo('is-success', this.$t('suppliers.newInvoiceModal.msgCorrect'))
        this.loadingData = false
        this.$emit('new-invoice')
        this.$parent.close()
      })
        .catch(
          () => {
            this.showInfo('is-danger', this.$t('suppliers.newInvoiceModal.msgIncorrect'))
            this.loadingData = false
          })
    },
    deleteFile () {
      this.invoiceFile = null
    },
  },
}
</script>
