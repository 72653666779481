import adminAxios from '@services/adminAxios'
import { getListInfo } from '@/helpers/ServiceHelpers'

export default {
  index (filters, order, page, perPage = 10) {
    const info = getListInfo(filters, order, page, perPage)
    return adminAxios().get('/users?' +
      info.infoFilters + info.infoOrder + info.infoPage)
  },
  getUser () {
    return adminAxios().get('/user')
  },
  showUser (id) {
    return adminAxios().get(`/users/${id}`)
  },
  getUsers () {
    return adminAxios().get('/users')
  },
  createUser (data) {
    return adminAxios().post('/users', data)
  },
  updateUser (id, data) {
    return adminAxios().put(`/users/${id}`, data)
  },
  deleteUser (id) {
    return adminAxios().delete(`/users/${id}`)
  },
  changePassword (id, data) {
    return adminAxios().put(`/users/${id}/change-password`, data)
  },
  askRecoveryPassword (email) {
    return adminAxios().get(`/recovery-password-admin/${email}`)
  },
  recoverPassword (user) {
    return adminAxios().put(`/change-password-recovery-admin/${user.token_temp}`, user)
  },
  changeActiveNotifications (id) {
    return adminAxios().post(`/users/${id}/change-active-notifications`)
  },
  changeActiveEmailNotifications (id) {
    return adminAxios().post(`/users/${id}/change-active-email-notifications`)
  },
  getNotifications () {
    return adminAxios().get('/user/notifications')
  },
  markUnreadNotificationsAsRead () {
    return adminAxios().post('/user/notifications/mark-as-read')
  },
  markNotificationAsRead (id) {
    return adminAxios().post(`/user/notifications/${id}/mark-as-read`)
  },
}
