<template>
  <div v-if="!loadingData" class="card card-list card-supplier-form card-table-full-height">
    <header class="card-header">
      <p class="card-header-title">
        {{ $t('admin.supplierForm.title') }}
      </p>
      <b-button type="is-dark"
                :disabled="disabledAccessAsProviderButton"
                @click="impersonate"
      >
        {{ $t('admin.supplierForm.accessAsProvider') }}
      </b-button>
    </header>
    <!-- <ValidationObserver ref="observer" v-slot="{invalid}"> -->
    <div class="card-content-multiple">
      <!-- <ValidationProvider v-slot="{errors}" :rules="{required: true, min: 8, max: 50}"> -->
      <div class="card-profile-info">
        <!-- columns first row -->
        <div class="columns">
          <!-- name-->
          <div class="column is-one-quarter">
            <b-field :label="$t('admin.supplierForm.name')">
              <b-input v-model="supplier.name"
                       name="name"
                       disabled
              />
            </b-field>
          </div>

          <!-- </ValidationProvider> -->
          <!-- <ValidationProvider v-slot="{errors}" :rules="{required: true, min: 8, max: 50}"> -->
          <!-- email-->
          <div class="column is-one-quarter">
            <b-field :label="$t('admin.supplierForm.email')">
              <b-input v-model="supplier.email"
                       name="email"
                       disabled
              />
            </b-field>
          </div>

          <!-- </ValidationProvider> -->
          <!-- NIF-->
          <div class="column is-one-quarter">
            <b-field :label="$t('admin.supplierForm.nif')">
              <b-input v-model="supplier.nif"
                       name="nif"
                       disabled
              />
            </b-field>
          </div>
          <!-- phone-->
          <div class="column is-one-quarter">
            <b-field :label="$t('admin.supplierForm.phone')">
              <b-input v-model="supplier.phone"
                       name="phone"
                       disabled
              />
            </b-field>
          </div>
        </div>
        <!-- columns second row -->
        <div class="columns">
          <!-- first address-->
          <div class="column is-one-quarter">
            <b-field :label="$t('admin.supplierForm.firstAddress')">
              <b-input v-model="supplier.firstAddress"
                       name="firstAddress"
                       disabled
              />
            </b-field>
          </div>
          <!-- second address-->
          <div class="column is-one-quarter">
            <b-field :label="$t('admin.supplierForm.secondAddress')">
              <b-input v-model="supplier.secondAddress"
                       name="secondAddress"
                       disabled
              />
            </b-field>
          </div>
        </div>
      </div>
      <UsersSuppliersForm v-if="idSupplier" v-model="supplier.suppliers" :id-supplier="idSupplier" />
    </div>
    <b-loading :is-full-page="isFullPage" :active.sync="loadingData" :can-cancel="true">
      <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin" />
    </b-loading>
    <!-- <footer class="card-footer">
      <b-button type="is-primary" class="u-mx-05 " :disabled="invalid" @click="changePassword">
        {{ $t('admin.supplierForm.changePassword') }}
      </b-button>
    </footer> -->
    <!-- </ValidationObserver> -->
  </div>
</template>
<script>
import SuppliersService from '@services/SuppliersService'
import UsersSuppliersForm from '@components/Suppliers/UsersSuppliersForm'

export default {
  name: 'SupplierForm',
  components: {
    UsersSuppliersForm,
  },
  props: {
    idSupplier: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      loadingData: false,
      isFullPage: false,
      supplier: {},
    }
  },
  computed: {
    disabledAccessAsProviderButton () {
      return this.supplier.suppliers.length === 0
    },
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      if (this.idSupplier) {
        this.loadingData = true
        await this.getSupplier()
        this.loadingData = false
      }
    },
    async getSupplier () {
      this.supplier = await SuppliersService.showSupplier(this.idSupplier)
      this.formatUsersSuppliers()
    },
    async impersonate () {
      this.token = await SuppliersService.impersonate(this.idSupplier)
      /* eslint-disable no-undef */
      const protocol = process.env.MIX_APP_SUPPLIERS_PROTOCOL
      const domain = process.env.MIX_APP_SUPPLIERS_DOMAIN
      const prefix = process.env.MIX_APP_SUPPLIERS_PREFIX
      const suppliersUrl = `${protocol}://${domain}/${prefix}?token=${this.token}`
      /* eslint-enable no-undef */
      window.open(suppliersUrl, '_blank')
    },
    formatUsersSuppliers () {
      for (const user of this.supplier.suppliers) {
        this.$set(user, 'disabled', true)
      }
    },
  },
}
</script>
