/**
 * listStructure: Requiere que se establezcan listUrl, createUrl, icon en el 'padre'
 * adminFormStructure: Requiere que se establezcan listUrl, createUrl, icon, itemId, itemName en el 'padre'
 */
export default {
  computed: {
    componentName () {
      const name = this.$options.name
      return name.charAt(0).toLowerCase() + name.substring(1)
    },
    listStructure () {
      const structure = {
        titleClass: 'title',
        title: this.$t(`${this.translationsPrefix}.${this.componentName}.title`),
      }

      return structure
    },
    formStructure () {
      const structure = {
        segments: this.segments,
        buttons: [
          {
            name: this.$t(`${this.translationsPrefix}.${this.componentName}.back`),
            url: this.backUrl,
            type: 'is-text is-paddingless',
            icon: ' icon-custom icon-back',
          },
        ],
      }

      return structure
    },
  },
}
