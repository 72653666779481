import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'

import { Getters } from '@store/suppliers/types/getters'
import { Mutations } from '@store/suppliers/types/mutations'
import { Actions } from '@store/suppliers/types/actions'

import UsersSuppliersService from '@services/UsersSuppliersService'

import { getVueI18nInstance } from '@/i18n'

Vue.use(Vuex)

const i18n = getVueI18nInstance('suppliers')
// eslint-disable-next-line no-undef
const debug = process.env.NODE_ENV !== 'production'

let notificationsTimer = null

export default new Vuex.Store({
  modules: {
  },
  state: {
    user: null,
    translationsPrefix: 'suppliers',
    numberIncidents: 0,
    numberDocuments: 0,
    orderStatus: null,
    language: i18n.locale,
  },
  mutations: {
    [Mutations.SET_USER] (state, user) {
      user.notifications = state.user?.notifications
      state.user = user
    },
    [Mutations.SET_NOTIFICATIONS] (state, notifications) {
      state.user.notifications = notifications
    },
    [Mutations.SET_NUMBER_INCIDENTS] (state, numberIncidents) {
      state.numberIncidents = numberIncidents
    },
    [Mutations.SET_NUMBER_DOCUMENTS] (state, numberDocuments) {
      state.numberDocuments = numberDocuments
    },
    [Mutations.SET_ORDER_STATUS] (state, orderStatus) {
      state.orderStatus = orderStatus
    },
    [Mutations.CHANGE_READ_VALUE_NOTIFICATION] (state, idNotification) {
      const notificationToChange = state.user.notifications.find(notification => notification.id === idNotification)
      notificationToChange.read = !notificationToChange.read
    },
    [Mutations.SET_LANGUAGE] (state, language) {
      state.language = language
      localStorage.setItem('language', language)
      location.reload()
    },
  },
  getters: {
    [Getters.GET_USER]: state => state.user,
    [Getters.GET_TRANSLATIONS_PREFIX]: state => state.translationsPrefix,
    [Getters.GET_NUMBER_INCIDENTS]: state => state.numberIncidents,
    [Getters.GET_NUMBER_DOCUMENTS]: state => state.numberDocuments,
    [Getters.GET_ORDER_STATUS]: state => state.orderStatus,
    [Getters.GET_LANGUAGE]: state => state.language,
  },
  actions: {
    async [Actions.FETCH_USER] ({ commit, dispatch }) {
      // TODO: When token has data, this should be change
      // this.idUser = (JSON.parse(atob((localStorage.getItem('access_token')).split('.')[1]))).sub
      const supplier = await UsersSuppliersService.getUserSupplier()
      commit(Mutations.SET_USER, supplier)

      if (!notificationsTimer) {
        await dispatch(Actions.FETCH_NOTIFICATIONS)
        // Actualizar notificaciones cada 60s
        notificationsTimer = setInterval(() => dispatch(Actions.FETCH_NOTIFICATIONS), 60 * 1000)
      }
    },
    async [Actions.SET_NUMBER_INCIDENTS] ({ commit }, payload) {
      commit(Mutations.SET_NUMBER_INCIDENTS, payload)
    },
    async [Actions.SET_NUMBER_DOCUMENTS] ({ commit }, payload) {
      commit(Mutations.SET_NUMBER_DOCUMENTS, payload)
    },
    async [Actions.SET_ORDER_STATUS] ({ commit }, payload) {
      commit(Mutations.SET_ORDER_STATUS, payload)
    },
    async [Actions.FETCH_NOTIFICATIONS] ({ commit }) {
      const response = await UsersSuppliersService.getNotifications()
      const notifications = response.data
      commit(Mutations.SET_NOTIFICATIONS, notifications)
    },
    async [Actions.CHANGE_READ_VALUE_NOTIFICATION] ({ commit }, idNotification) {
      commit(Mutations.CHANGE_READ_VALUE_NOTIFICATION, idNotification)
    },
    async [Actions.SET_LANGUAGE] ({ commit }, payload) {
      commit(Mutations.SET_LANGUAGE, payload)
    },
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
})
