import supplierAxios from '@services/supplierAxios'

export default {
  index () {
    return supplierAxios().get('/users-suppliers')
  },
  getUserSupplier () {
    return supplierAxios().get('/user-supplier')
  },
  getUserSuppliers () {
    return supplierAxios().get('/users-suppliers')
  },
  createUserSupplier (data) {
    return supplierAxios().post('/users-suppliers', data)
  },
  updateUserSupplier (id, data) {
    return supplierAxios().put(`/users-suppliers/${id}`, data)
  },
  deleteUserSupplier (id) {
    return supplierAxios().delete(`/users-suppliers/${id}`)
  },
  changePassword (id, data) {
    return supplierAxios().put(`/users-suppliers/${id}/change-password`, data)
  },
  askRecoveryPassword (email) {
    return supplierAxios().get(`/recovery-password-supplier/${email}`)
  },
  recoverPassword (userSupplier) {
    return supplierAxios().put(`/change-password-recovery-supplier/${userSupplier.token_temp}`, userSupplier)
  },
  changeActiveNotifications (id) {
    return supplierAxios().post(`/users-suppliers/${id}/change-active-notifications`)
  },
  changeActiveEmailNotifications (id) {
    return supplierAxios().post(`/users-suppliers/${id}/change-active-email-notifications`)
  },
  getNotifications () {
    return supplierAxios().get('/user-supplier/notifications')
  },
  markUnreadNotificationsAsRead () {
    return supplierAxios().post('/user-supplier/notifications/mark-as-read')
  },
  markNotificationAsRead (id) {
    return supplierAxios().post(`/user-supplier/notifications/${id}/mark-as-read`)
  },
}
