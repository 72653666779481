<template>
  <div v-if="!loadingData" class="card-content card-details-content card-details-order-info">
    <!--card content-->
    <!--first column row-->
    <div class="columns">
      <!--name column-->
      <div class="column is-one-quarter">
        <b-field :label="$t('suppliers.orderForm.orderInformation.eiffageSociety')">
          <b-input v-model="order.eiffage_society"
                   name="eiffageSociety"
                   disabled
          />
        </b-field>
      </div>
      <!--freight column-->
      <div class="column is-one-quarter">
        <b-field :label="$t('suppliers.orderForm.orderInformation.freight')">
          <b-input v-model="order.freight"
                   name="freight"
                   disabled
          />
        </b-field>
      </div>
      <!--deliver time column-->
      <div class="column is-one-quarter">
        <b-field :label="$t('suppliers.orderForm.orderInformation.deliveryTime')">
          <b-input v-model="order.delivery_time"
                   name="deliveryTime"
                   disabled
          />
        </b-field>
      </div>
      <!--certification type column-->
      <div class="column is-one-quarter">
        <b-field :label="$t('suppliers.orderForm.orderInformation.certificateType')">
          <b-input v-model="order.certificate_type"
                   name="certificateType"
                   disabled
          />
        </b-field>
      </div>
    </div>
    <!--second column row-->
    <div class="columns">
      <!--order-information column-->
      <div class="column is-one-quarter">
        <b-field :label="$t('suppliers.orderForm.orderInformation.paymentTerms')">
          <b-input v-model="order.payment_terms"
                   name="paymentTerms"
                   disabled
          />
        </b-field>
      </div>
      <!--delivery conditions column-->
      <div class="column is-one-quarter">
        <b-field :label="$t('suppliers.orderForm.orderInformation.deliveryConditions')">
          <b-input v-model="order.delivery_conditions"
                   name="deliveryConditions"
                   disabled
          />
        </b-field>
      </div>
      <!--order address column-->
      <div class="column is-half">
        <b-field :label="$t('suppliers.orderForm.orderInformation.deliveryAddress')">
          <b-input v-model="order.delivery_address"
                   name="deliveryAddress"
                   disabled
          />
        </b-field>
      </div>
    </div>
    <!--third column row-->
    <div class="columns">
      <!--order tax-base column-->
      <div class="column is-one-quarter">
        <b-field :label="$t('suppliers.orderForm.orderInformation.taxBase')">
          <b-input v-model="order.tax_base"
                   name="taxBase"
                   disabled
          />
        </b-field>
      </div>
      <!--taxes percent column-->
      <div class="column is-one-quarter">
        <b-field :label="$t('suppliers.orderForm.orderInformation.vaxPercentage')">
          <b-input v-model="order.vax_percentage"
                   name="vaxPercentage"
                   disabled
          />
        </b-field>
      </div>
      <!--taxes amount column-->
      <div class="column is-one-quarter">
        <b-field :label="$t('suppliers.orderForm.orderInformation.vaxAmount')">
          <b-input v-model="order.vax_amount"
                   name="vaxAmount"
                   disabled
          />
        </b-field>
      </div>
      <!--total amount column-->
      <div class="column is-one-quarter">
        <b-field :label="$t('suppliers.orderForm.orderInformation.totalAmount')">
          <b-input v-model="order.total_amount"
                   name="totalAmount"
                   disabled
          />
        </b-field>
      </div>
    </div>
    <!--fourth column row-->
    <div class="columns columns-text-area">
      <!--observation column-->
      <div class="column is-full">
        <b-field :label="$t('suppliers.orderForm.orderInformation.observations')" class="is-full-height">
          <b-input v-model="order.observations"
                   name="observations"
                   disabled
                   type="textarea"
          />
        </b-field>
      </div>
    </div>
    <b-loading :is-full-page="isFullPage" :active.sync="loadingData" :can-cancel="true">
      <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin" />
    </b-loading>
  </div>
</template>

<script>
import OrdersService from '@services/OrdersService'
import dayjs from 'dayjs'

export default {
  name: 'OrderInformation',
  props: {
    numOrder: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      dayjs,
      loadingData: false,
      isFullPage: false,
      order: {},
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      if (this.numOrder) {
        this.loadingData = true
        await this.getOrder()
        this.loadingData = false
      }
    },
    async getOrder () {
      const response = await OrdersService.showOrder(this.numOrder)
      this.order = response.data
      // this.order.delivery_time = dayjs(this.order.delivery_time).format('DD/MM/YYYY')
    },
  },
}
</script>
