export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      // TODO: Ver la posibilidad de clonar al 100% para no modificar nada del padre y si merece realmente la pena
      rows: [...this.value],
    }
  },
  /* eslint-disable object-shorthand */
  watch: {
    value: function (newValue) {
      this.rows = [...newValue]
    },
  },
  /* eslint-enable object-shorthand */
  methods: {
    addRow (newRow) {
      this.rows.push(newRow)
      this.emit()
    },
    deleteRow (index) {
      this.rows.splice(index, 1)
      this.emit()
    },
    changeRow (index, row) {
      this.rows[index] = row
      this.emit()
    },
    emit () {
      this.$emit('input', this.rows)
    },
  },
}
