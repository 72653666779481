<template>
  <SubHeader :is-loaded="true" :structure="listStructure" />
</template>
<script>
import SubHeader from '@/components/SubHeader.vue'
import SubHeaderMixin from '@/mixins/SubHeader'
import { Getters } from '@store/suppliers/types/getters'
import { mapGetters } from 'vuex'

export default {
  name: 'OrdersListSubHeader',
  components: {
    SubHeader,
  },
  mixins: [SubHeaderMixin],
  computed: {
    ...mapGetters({
      translationsPrefix: Getters.GET_TRANSLATIONS_PREFIX,
    }),
  },
}
</script>
