import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const loadLocaleMessages = localesPrefix => {
  // eslint-disable-next-line no-undef
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1 && key.startsWith(`./${localesPrefix}`)) {
      const locale = matched[1]
      messages[locale] = { [localesPrefix]: locales(key) }
    }
  })
  return messages
}

const language = () => localStorage.getItem('language') ?? 'es'

const getVueI18nInstance = localesPrefix =>  new VueI18n({
  locale: language(),
  fallbackLocale: 'es',
  messages: loadLocaleMessages(localesPrefix),
})

export default getVueI18nInstance

export { getVueI18nInstance }
