<template>
  <div class="filter">
    <slot />
  </div>
</template>

<script>
import _ from 'lodash'
import linq from 'js-linq'

export default {
  name: 'NbFilter',
  props: {
    field: {
      type: String,
      required: true,
    },
    operator: {
      type: String,
      default: '=',
    },
    debounce: {
      type: Number,
      default: 250,
    },
    minSearchLength: {
      type: Number,
      default: 0,
    },
    defaultValue: {
      type: [String, Number, Boolean, Array, Object, Date],
      default: null,
    },
    defaultOnCreate: {
      type: Boolean,
    },
    value: {
      type: [String, Number, Boolean, Array, Object, Date],
      default: null,
    },
  },
  data () {
    return {
      debouncedEmit: null,
    }
  },
  watch: {
    value (value, oldValue) {
      // Si estamos en liveSearch emitimos el evento si se cumplen las restricciones
      // Valor nulo - suele indicar reseteo de filtro o filtro inicial
      let refreshSearch = _.isNil(value)
      // Valor sin propiedad longitud - Por ejemplo valores numéricos
      refreshSearch = refreshSearch || _.isNil(value.length)
      // Longitud mínima / Borrado completo
      refreshSearch = refreshSearch || value.length >= this.minSearchLength || (!value.length && oldValue.length)
      if (this.$parent.liveSearch && refreshSearch) {
        const emit = _.debounce(() => {
          this.$nextTick(() => {
            this.$parent.$emit('filter', linq.from(this.$parent.filters).select('f => f._props').toArray())
          })
        }, this.debounce)
        if (this.debouncedEmit) {
          this.debouncedEmit.cancel()
        }
        this.debouncedEmit = emit
        emit()
      }
    },
  },
  created () {
    if (!this.$parent.$data.isNbSearcher) {
      this.$destroy()
      throw new Error('You should wrap nbFilter on a nbSearcher')
    }

    if (this.defaultOnCreate) {
      this.$emit('update:value', this.defaultValue)
    }

    this.$parent.filters.push(this)
  },
}
</script>
