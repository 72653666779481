const Mutations = {
  SET_USER: 'setUser',
  SET_NUMBER_INCIDENTS: 'setNumberIncidents',
  SET_NUMBER_DOCUMENTS: 'setNumberDocuments',
  SET_ORDER_STATUS: 'setOrderStatus',
  SET_NOTIFICATIONS: 'setNotifications',
  CHANGE_READ_VALUE_NOTIFICATION: 'changeReadValueNotification',
  SET_LANGUAGE: 'setLanguage',
}

// const {ModuleName}Mutations = { }

export { Mutations /* {ModuleName}Mutations */ }
