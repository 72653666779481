const Getters = {
  GET_USER: 'getUser',
  GET_TRANSLATIONS_PREFIX: 'getTranslationsPrefix',
  GET_NUMBER_INCIDENTS: 'getNumberIncidents',
  GET_ORDER_STATUS: 'getOrderStatus',
  GET_LANGUAGE: 'getLanguage',
}

//const {Modulename}Getters = { }

export { Getters /* {Modulename}Getters */ }
