var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loadingData)?_c('div',{staticClass:"card card-tech-form"},[_c('div',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v("\n      "+_vm._s(_vm.getTextHeader())+"\n    ")]),_vm._v(" "),_vm._t("default",[_c('b-button',{staticClass:"button-square",attrs:{"type":"is-text","icon-pack":"icon-custom","icon-left":"icon-trash icon-trash-primary","disabled":_vm.deleteButtonDisabled},on:{"click":_vm.deleteTech}})])],2),_vm._v(" "),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-full"},[_c('ValidationProvider',{attrs:{"rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('admin.techForm.name'),"type":{'is-danger': errors.length},"message":errors}},[_c('b-input',{attrs:{"name":"name"},model:{value:(_vm.tech.name),callback:function ($$v) {_vm.$set(_vm.tech, "name", $$v)},expression:"tech.name"}})],1)]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":{required: true, email: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('admin.techForm.email'),"type":{'is-danger': errors.length},"message":errors}},[_c('b-input',{attrs:{"name":"email"},model:{value:(_vm.tech.email),callback:function ($$v) {_vm.$set(_vm.tech, "email", $$v)},expression:"tech.email"}})],1)]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"column is-half"},[_c('b-field',{attrs:{"label":_vm.$t('admin.techForm.location')}},[_c('b-input',{attrs:{"name":"location"},model:{value:(_vm.tech.location),callback:function ($$v) {_vm.$set(_vm.tech, "location", $$v)},expression:"tech.location"}})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[_c('b-field',{attrs:{"label":_vm.$t('admin.techForm.department')}},[_c('b-input',{attrs:{"name":"department"},model:{value:(_vm.tech.department),callback:function ($$v) {_vm.$set(_vm.tech, "department", $$v)},expression:"tech.department"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('admin.techForm.roles.title'),"type":{'is-danger': errors.length},"message":errors}},[_c('b-select',{staticClass:"select",attrs:{"placeholder":_vm.$t('admin.techForm.roles.placeholder')},model:{value:(_vm.tech.role_id),callback:function ($$v) {_vm.$set(_vm.tech, "role_id", $$v)},expression:"tech.role_id"}},_vm._l((_vm.UserRole),function(role,index){return _c('option',{key:index,domProps:{"value":role.id}},[_vm._v("\n                  "+_vm._s(_vm.$t(("admin.techForm.roles." + (role.name))))+"\n                ")])}),0)],1)]}}],null,true)})],1)]),_vm._v(" "),_vm._l((_vm.tech.users_techs),function(user){return _c('div',{key:user.id},[_c('ValidationProvider',{attrs:{"rules":{required: true, min: 8, max: 50}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('admin.techForm.name'),"type":{'is-danger': errors.length},"message":errors}},[_c('b-input',{attrs:{"name":"name"},model:{value:(user.name),callback:function ($$v) {_vm.$set(user, "name", $$v)},expression:"user.name"}})],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":{required: true, min: 8, max: 50}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('admin.techForm.email'),"type":{'is-danger': errors.length},"message":errors}},[_c('b-input',{attrs:{"name":"email"},model:{value:(user.email),callback:function ($$v) {_vm.$set(user, "email", $$v)},expression:"user.email"}})],1)]}}],null,true)})],1)})],2),_vm._v(" "),_c('footer',{staticClass:"card-footer"},[_c('div',{staticClass:"card-actions is-paddingless"},[_c('div',{staticClass:"card-footer-item"},[_c('b-button',{staticClass:"is-fullwidth",attrs:{"type":"is-primary","disabled":invalid},on:{"click":_vm.getActionButton}},[_vm._v("\n            "+_vm._s(_vm.getTextButton())+"\n          ")])],1)])]),_vm._v(" "),_c('b-loading',{attrs:{"is-full-page":_vm.isFullPage,"active":_vm.loadingData,"can-cancel":true},on:{"update:active":function($event){_vm.loadingData=$event}}},[_c('b-icon',{attrs:{"pack":"fas","icon":"sync-alt","size":"is-large","custom-class":"fa-spin"}})],1)]}}],null,false,2953839919)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }