<template>
  <div class="app-container">
    <router-view name="sidebar" />
    <div class="main-content">
      <router-view name="subHeader" />
      <div class="main-content-body">
        <router-view name="content" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Container',
}
</script>
