<template>
  <aside id="asideMenu" class="box box-sidebar box-sidebar-dark">
    <div class="box-actions">
      <!-- Suppliers -->
      <router-link to="/proveedores"
                   class="button button-box"
                   active-class="link-active"
      >
        <em class="icon icon-custom icon-provider icon-provider-white" />
        <span class="button-box-text">{{ $t('admin.adminSidebar.suppliersManagement') }}</span>
      </router-link>
      <!-- Techs -->
      <router-link v-if="userIsAdmin"
                   to="/tecnicos"
                   class="button button-box"
                   active-class="link-active"
      >
        <em class="icon icon-custom icon-tech icon-tech-white" />
        <span class="button-box-text">{{ $t('admin.adminSidebar.techsManagement') }}</span>
      </router-link>
      <!-- Files -->
      <router-link to="/archivos"
                   class="button button-box"
                   active-class="link-active"
      >
        <em class="icon icon-custom icon-files icon-files-white" />
        <span class="button-box-text">{{ $t('admin.adminSidebar.filesManagement') }}</span>
      </router-link>
      <!-- Settings -->
      <!-- <router-link to="/configuracion"
                   class="button button-box"
                   active-class="link-active"
      >
        <i class="icon icon-custom icon-config icon-config-white" />
        <span class="button-box-text">{{ $t('admin.adminSidebar.settings') }}</span>
      </router-link> -->
    </div>
  </aside>
</template>
<script>
import { Getters } from '@store/admin/types/getters'
import { mapGetters } from 'vuex'
import { UserRole } from '@/enums/UserRole'

export default {
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      loggedUser: Getters.GET_USER,
    }),
    userIsAdmin () {
      return this.loggedUser.role_id === UserRole.Admin.id
    },
  },
}
</script>
