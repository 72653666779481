<template>
  <div class="card card-list card-suppliers card-table-full-height">
    <!--card header-->
    <header class="card-header">
      <p class="card-header-title">
        {{ $t('admin.documentsList.title') }}
      </p>
    </header>
    <!--card filter-->
    <div class="card-filter">
      <nb-searcher class="card-filter-fields columns columns-filters"
                   :text-reset-button="$t('admin.documentsList.deleteFilter')"
                   class-reset-button="button is-primary is-fullwidth"
                   live-search
                   @reset="reset"
      >
        <!-- name search -->
        <div class="column">
          <nb-filter class="card-filter-field"
                     field="date"
                     operator="like"
                     :value.sync="filters.name"
          >
            <label class="label">{{ $t('admin.documentsList.searcher') }}</label>
            <div class="control has-icons-right">
              <label for="search-input" />
              <b-input id="search-input"
                       v-model="filters.name"
                       icon-pack="icon-custom"
                       icon-right="icon-search icon-search-grey-lighter"
                       type="search"
                       :placeholder="$t('admin.documentsList.searcherPlaceholder')"
              />
            </div>
          </nb-filter>
        </div>
        <!-- status select -->
        <div class="column">
          <nb-filter class="card-filter-field"
                     field="status"
                     operator="like"
                     :value.sync="filters.supplier_name"
          >
            <label class="label">{{ $t('admin.documentsList.supplier') }}</label>
            <div class="control has-icons-right">
              <b-select v-model="filters.supplier_name"
                        :placeholder="$t('admin.documentsList.selectSupplier')"
                        class="field--select field--select-grey"
              >
                <option value>
                  {{ $t('admin.documentsList.allSuppliers') }}
                </option>
                <option v-for="(supplier, index) in suppliersList" :key="index">
                  {{ supplier }}
                </option>
              </b-select>
            </div>
          </nb-filter>
        </div>
        <div class="column">
          <nb-filter class="card-filter-field"
                     field="fromDate"
                     operator="like"
                     :value.sync="filters.fromDate"
          >
            <label class="label">{{ $t('admin.documentsList.fromDate') }}</label>
            <b-datepicker v-model="filters.fromDate"
                          :placeholder="$t('admin.documentsList.fromDatePlaceholder')"
                          position="is-bottom-left"
                          class="select"
                          custom-class="u-bg-grey"
            />
          </nb-filter>
        </div>
        <div class="column">
          <nb-filter class="card-filter-field"
                     field="toDate"
                     operator="like"
                     :value.sync="filters.toDate"
          >
            <label class="label">{{ $t('admin.documentsList.toDate') }}</label>
            <b-datepicker v-model="filters.toDate"
                          :placeholder="$t('admin.documentsList.toDatePlaceholder')"
                          position="is-bottom-left"
                          class="select"
                          custom-class="u-bg-grey"
            />
          </nb-filter>
        </div>
      </nb-searcher>
    </div>
    <div class="card-content card-content-scroll">
      <b-table :data="filteredDocuments"
               paginated
               :loading="loading"
               :default-sort-direction="defaultSortDirection"
               :default-sort="defaultSortField"
               sticky-header
               :per-page="infoPagination.per_page"
               class="table is-striped table-list-control table-scroll table-pagination"
      >
        <!-- Document Supplier -->
        <b-table-column v-slot="props"
                        field="supplier_name"
                        :label="$t('admin.documentsList.supplierName')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          {{ props.row.supplier_name }}
        </b-table-column>
        <!-- Document Name -->
        <b-table-column v-slot="props"
                        field="name"
                        :label="$t('admin.documentsList.name')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          {{ props.row.name }}
        </b-table-column>
        <!-- Document Type -->
        <b-table-column v-slot="props"
                        field="type"
                        :label="$t('admin.documentsList.type')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          {{ props.row.type }}
        </b-table-column>
        <!-- Document Date -->
        <b-table-column v-slot="props"
                        field="date"
                        :label="$t('admin.documentsList.date')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          {{ dayjs(props.row.date).format('DD/MM/YYYY') }}
        </b-table-column>
        <!--actions-->
        <b-table-column v-slot="props"
                        :label="$t('admin.documentsList.actions')"
                        header-class="table-title"
                        centered
                        field="actions"
                        cell-class="table-actions"
        >
          <div class="table-item">
            <b-button
              class="button-square"
              icon-pack="icon-custom"
              icon-left="icon-eye icon-eye-gray"
              type="is-text"
              @click="downloadDocument(props.row.id, props.row.type, props.row.name)"
            />
          </div>
        </b-table-column>
        <template #empty>
          <div class="has-text-centered">
            {{ $t('admin.documentsList.noData') }}
          </div>
        </template>
      </b-table>
    </div>
    <!--card footer-->
    <!-- <div class="card-footer">
      <div class="card-footer-item">
        <b-pagination
          :total="infoPagination.total"
          :current.sync="infoPagination.current_page"
          order="is-centered"
          size="is-small"
          :simple="false"
          :per-page="infoPagination.per_page"
          @update:current="getTechs"
        />
      </div>
    </div> -->
  </div>
</template>
<script>
import DocumentsService from '@/services/DocumentsService'
import Notifications from '@/mixins/Notifications'
import { downloadFromBlob } from '@/helpers/FilesHelpers'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import _ from 'lodash'
import { Getters } from '@store/suppliers/types/getters'
import { mapGetters } from 'vuex'

export default {
  name: 'DocumentsList',
  mixins: [Notifications],
  data () {
    return {
      filters: {},
      documents: [],
      loading: false,
      defaultSortDirection: 'asc',
      defaultSortField: 'date',
      infoPagination: { current_page: 1, per_page: 8, total: 0 },
      dayjs,
      isSameOrAfter,
      isSameOrBefore,
      suppliersList: [],
    }
  },
  computed: {
    ...mapGetters({
      translationsPrefix: Getters.GET_TRANSLATIONS_PREFIX,
    }),
    filteredDocuments () {
      return this.documents.filter(document => {
        const correctNumDocument = document.name
          .toString()
          .toLowerCase()
          .indexOf(this.filters.name.toLowerCase()) >= 0
        const correctSupplier = document.supplier_name
          .toString()
          .indexOf(this.filters.supplier_name) >= 0
        const correctFromDate = this.filters.fromDate ? (dayjs(document.date).set('hour', 0))
          .isSameOrAfter(dayjs(this.filters.fromDate)) : true
        const correctToDate = this.filters.toDate ? (dayjs(document.date).set('hour', 0))
          .isSameOrBefore(dayjs(this.filters.toDate)) : true

        return correctNumDocument && correctSupplier && correctFromDate && correctToDate
      })
    },
  },
  created () {
    this.init()
    dayjs.extend(isSameOrBefore)
    dayjs.extend(isSameOrAfter)
  },
  methods: {
    async init () {
      this.reset()
      await this.getDocuments()
      this.getSuppliersList()
    },
    reset () {
      this.filters = {
        name: '',
        supplier_name: '',
        fromDate: null,
        toDate: null,
      }
    },
    async getDocuments () {
      this.loading = true
      try {
        const results = await DocumentsService.index()
        this.documents = results.data
        this.infoPagination.total = this.documents.length
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    downloadDocument (id, type, name) {
      DocumentsService.downloadDocument(id, type)
        .then(response => {
          const type = response.headers.content_type
          const fileData = new Blob([response.data], { type })
          downloadFromBlob(fileData, name + '.pdf')
          this.showInfo('is-success', this.$t('admin.documentsList.msgCorrect'))
        })
        .catch(() => this.showInfo('is-danger', this.$t('admin.documentsList.msgIncorrect')))
    },
    getSuppliersList () {
      this.suppliersList = _.uniq(this.documents.map(document => document.supplier_name))
      _.pull(this.suppliersList, '')
    },
  },
}
</script>
