const getUrlFromBlob = blob => window.URL.createObjectURL(blob)

const downloadFromBlob = (blob, name) => {
  if (window.navigator.msSaveOrOpenBlob) {
    // Internet explorer
    window.navigator.msSaveOrOpenBlob(blob, name)
  } else {
    const link = document.createElement('a')
    const url = getUrlFromBlob(blob)
    link.href = url
    link.download = name

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    window.URL.revokeObjectURL(url)
  }
}

export { downloadFromBlob }
