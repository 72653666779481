<template>
  <div class="card card-list card-order-list card-table-full-height">
    <!--card header-->
    <header class="card-header">
      <p class="card-header-title">
        {{ $t('suppliers.ordersList.title') }}
      </p>
    </header>
    <!--card filter-->
    <div class="card-filter">
      <nb-searcher class="card-filter-fields columns columns-filters"
                   :text-reset-button="$t('suppliers.ordersList.deleteFilter')"
                   class-reset-button="button is-primary is-fullwidth"
                   live-search
                   auto-reset
                   @reset="reset"
      >
        <!-- date select -->
        <div class="column is-one-quarter">
          <nb-filter class="card-filter-field"
                     field="date"
                     operator="like"
                     :value.sync="filters.numDocument"
          >
            <label class="label">{{ $t('suppliers.ordersList.numDocument') }}</label>
            <div class="control has-icons-right">
              <label for="search-input" />
              <b-input id="search-input"
                       v-model="filters.numDocument"
                       icon-pack="icon-custom"
                       icon-right="icon-search icon-search-grey-lighter"
                       type="search"
                       :placeholder="$t('suppliers.ordersList.numDocument')"
              />
            </div>
          </nb-filter>
        </div>
        <!-- status select -->
        <div class="column is-2">
          <nb-filter class="card-filter-field"
                     field="status"
                     operator="like"
                     :value.sync="filters.status"
          >
            <label class="label">{{ $t('suppliers.ordersList.status') }}</label>
            <div class="control has-icons-right">
              <b-select v-model="filters.status"
                        :placeholder="$t('suppliers.ordersList.selectStatus')"
                        class="select"
              >
                <option value>
                  {{ $t('suppliers.statusList.all') }}
                </option>
                <option v-for="(status, index) in DocumentStatus" :key="index">
                  {{ $t(`suppliers.statusList.${status}`) }}
                </option>
              </b-select>
            </div>
          </nb-filter>
        </div>
        <!-- date ini select -->
        <div class="column is-2">
          <nb-filter class="card-filter-field"
                     field="fromDate"
                     operator="like"
                     :value.sync="filters.fromDate"
          >
            <label class="label">{{ $t('suppliers.ordersList.fromDate') }}</label>
            <b-datepicker v-model="filters.fromDate"
                          :placeholder="$t('suppliers.ordersList.fromDatePlaceholder')"
                          position="is-bottom-left"
                          class="select"
            />
          </nb-filter>
        </div>
        <!-- date end select -->
        <div class="column is-2">
          <nb-filter class="card-filter-field"
                     field="toDate"
                     operator="like"
                     :value.sync="filters.toDate"
          >
            <label class="label">{{ $t('suppliers.ordersList.toDate') }}</label>
            <b-datepicker v-model="filters.toDate"
                          :placeholder="$t('suppliers.ordersList.toDatePlaceholder')"
                          position="is-bottom-left"
                          class="select"
            />
          </nb-filter>
        </div>
      </nb-searcher>
    </div>
    <div class="card-content card-content-scroll">
      <b-table :data="filteredOrders"
               paginated
               :loading="loading"
               :default-sort-direction="defaultSortDirection"
               :default-sort="defaultSortField"
               pagination-position="bottom"
               sticky-header
               :per-page="infoPagination.per_page"
               class="table is-striped table-list-control table-scroll table-pagination"
      >
        <!-- Order Nº Document -->
        <b-table-column v-slot="props"
                        field="num_document"
                        :label="$t('suppliers.ordersList.numDocument')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          <router-link :to="`/pedidos/${props.row.num_document}`">
            {{ props.row.num_document }}
          </router-link>
        </b-table-column>
        <!-- Order Date -->
        <b-table-column v-slot="props"
                        field="date"
                        :label="$t('suppliers.ordersList.date')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          {{ dayjs(props.row.date).format('DD/MM/YYYY') }}
        </b-table-column>
        <!-- Order Status -->
        <b-table-column v-slot="props"
                        field="status"
                        :label="$t('suppliers.ordersList.status')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          <div :class="'status ' + getStatusClass(props.row.status)">
            {{ $t(`suppliers.statusList.${props.row.status}`) }}
            <span v-if="showNumIncidents(props.row.status, props.row.num_incidents)"
                  class="status-count"
            >
              {{ props.row.num_incidents }}
            </span>
          </div>
        </b-table-column>
        <!-- Order Total Amount -->
        <b-table-column v-slot="props"
                        field="total_amount"
                        :label="$t('suppliers.ordersList.totalAmount')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          {{ props.row.total_amount }} {{ $t('suppliers.ordersList.euro') }}
        </b-table-column>
        <!-- Order Delivery Time -->
        <b-table-column v-slot="props"
                        field="delivery_time"
                        :label="$t('suppliers.ordersList.deliveryTime')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          {{ dayjs(props.row.delivery_time).format('DD/MM/YYYY') }}
        </b-table-column>
        <!-- Order Payment Terms -->
        <b-table-column v-slot="props"
                        field="payment_terms"
                        :label="$t('suppliers.ordersList.paymentTerms')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          {{ props.row.payment_terms }}
        </b-table-column>
        <!--actions-->
        <b-table-column v-slot="props"
                        :label="$t('suppliers.ordersList.actions')"
                        header-class="table-title"
                        centered
                        field="actions"
                        cell-class="table-actions"
        >
          <div class="table-item">
            <b-button
              class="button-square"
              icon-pack="icon-custom"
              icon-left="icon-eye icon-eye-gray"
              type="is-text"
              @click="downloadDocument(props.row.id, props.row.type, props.row.name)"
            />
          </div>
        </b-table-column>
        <template #empty>
          <div class="has-text-centered">
            {{ $t('suppliers.ordersList.noData') }}
          </div>
        </template>
        <template #bottom-left>
          <div class="card-footer-item" />
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import OrdersService from '@/services/OrdersService'
import Notifications from '@/mixins/Notifications'
import { downloadFromBlob } from '@/helpers/FilesHelpers'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { DocumentStatus } from '@/enums/DocumentStatus'
import { Getters } from '@store/suppliers/types/getters'
import { mapGetters } from 'vuex'

export default {
  name: 'OrdersList',
  mixins: [Notifications],
  data () {
    return {
      filters: {},
      orders: [],
      loading: false,
      defaultSortDirection: 'asc',
      defaultSortField: 'date',
      infoPagination: { current_page: 1, per_page: 8, total: 0 },
      dayjs,
      isSameOrAfter,
      isSameOrBefore,
      DocumentStatus,
    }
  },
  computed: {
    ...mapGetters({
      translationsPrefix: Getters.GET_TRANSLATIONS_PREFIX,
    }),
    filteredOrders () {
      return this.orders.filter(order => {
        const correctNumDocument = order.num_document
          .toString()
          .toLowerCase()
          .indexOf(this.filters.numDocument.toLowerCase()) >= 0
        const correctStatus = order.status
          .toString()
          .indexOf(this.filters.status) >= 0
        const correctFromDate = this.filters.fromDate ? (dayjs(order.date).set('hour', 0))
          .isSameOrAfter(dayjs(this.filters.fromDate)) : true
        const correctToDate = this.filters.toDate ? (dayjs(order.date).set('hour', 0))
          .isSameOrBefore(dayjs(this.filters.toDate)) : true

        return correctNumDocument && correctStatus && correctFromDate && correctToDate
      })
    },
  },
  created () {
    this.init()
    dayjs.extend(isSameOrBefore)
    dayjs.extend(isSameOrAfter)
  },
  methods: {
    init () {
      this.reset()
      this.getOrders()
    },
    reset () {
      this.filters = {
        numDocument: '',
        status: '',
        fromDate: null,
        toDate: null,
      }
    },
    async getOrders () {
      this.loading = true
      try {
        const results = await OrdersService.index()
        this.orders = results.data
        this.infoPagination.total = this.orders.length
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    downloadDocument (id, type, name) {
      OrdersService.downloadDocument(id, type)
        .then(response => {
          const type = response.headers.content_type
          const fileData = new Blob([response.data], { type })
          downloadFromBlob(fileData, name + '.pdf')
          this.showInfo('is-success', this.$t('suppliers.ordersList.msgCorrect'))
        })
        .catch(() => this.showInfo('is-danger', this.$t('suppliers.ordersList.msgIncorrect')))
    },
    getStatusClass (status) {
      return 'status-' + status.toLowerCase().replaceAll('.', '').replaceAll(/\s/g, '-')
    },
    showNumIncidents (status, numIncidents) {
      return status === DocumentStatus.WithIncidents && numIncidents > 0
    },
  },
}
</script>
