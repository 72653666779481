const UserRole = {
  Admin: {
    id: 0,
    name: 'admin',
  },
  Tech: {
    id: 1,
    name: 'tech',
  },
}
export { UserRole }
