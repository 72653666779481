import Vue from 'vue'
import Router from 'vue-router'

import Login from '@views/suppliers/Login'
import Header from '@views/suppliers/Header'
import Container from '@views/admin/Container'
import NewPasswordForgotten from '@views/suppliers/NewPasswordForgotten'
import SuppliersSidebar from '@views/suppliers/SuppliersSidebar'
// Listado y formulario de pedidos
import OrdersList from '@views/suppliers/Orders/OrdersList'
import OrdersListSubHeader from '@views/suppliers/Orders/OrdersListSubHeader'
import OrderForm from '@views/suppliers/Orders/OrderForm'
import OrderFormSubHeader from '@views/suppliers/Orders/OrderFormSubHeader'


Vue.use(Router)

export default new Router({
  mode: 'history',
  // eslint-disable-next-line no-undef
  base: process.env.MIX_APP_SUPPLIERS_PREFIX,
  routes: [
    {
      path: '/login',
      name: 'login',
      components: {
        container: Login,
      },
      meta: { requiresAuth: false },
      props: { container: route => ({ redirect: route.query.redirect }) },
    },
    {
      path: '/password',
      name: 'reset-password',
      components: {
        container: NewPasswordForgotten,
      },
      meta: { requiresAuth: false },
    },
    {
      path: '/',
      components: {
        header: Header,
        container: Container,
      },
      meta: { requiresAuth: true },
      children: [
        {
          path: '/',
          alias: '/pedidos',
          name: 'orders',
          components: {
            content: OrdersList,
            sidebar: SuppliersSidebar,
            subHeader: OrdersListSubHeader,
          },
        },
        {
          path: '/pedidos/:id',
          name: 'detail-orders',
          components: {
            content: OrderForm,
            sidebar: SuppliersSidebar,
            subHeader: OrderFormSubHeader,
          },
          props: {
            subHeader: route => ({ numOrder: route.params.id }),
            content: route => ({ numOrder: route.params.id }),
          },
        },
      ],
    },
  ],
})
