import linq from 'js-linq'
import _ from 'lodash'
import dayjs from 'dayjs'

/**
 * Replica direction tantas veces como campos separados por , haya en fields
 *
 * @param fields string (campos seperados por ,)
 * @param direction string
 * @return string direcciones separadas por ,
 */
const getSortDirections = (fields, direction) =>
  linq.from(fields.split(',')).select(`f => "${direction}"`).toDelimitedString(',')

const processFilter = filter => {
  if (_.isNil(filter.value) || filter.value === '') {
    return ''
  }

  let infoFilter
  switch (filter.field) {
  case 'from':
  case 'to': {
    const valueTime = dayjs(filter.value).format('YYYY-MM-DD')
    infoFilter = `&created_at=${valueTime}|${filter.operator}`
    break
  }
  default:
    infoFilter = '&' + filter.field + '=' + filter.value + '|' + filter.operator
  }

  return infoFilter
}

const getListInfo = (filters, order, page, perPage = 10, processFiltersCallback, variableName) => {
  const infoOrder = order
    ? `&sort=${order.field}&sortDirection=${getSortDirections(order.field, order.direction)}` : ''
  const infoPage = page ? `&page=${page}&perPage=${perPage}` : ''
  let infoFilters = ''

  if (!_.isNil(filters)) {
    if (processFiltersCallback) {
      if (variableName) {
        infoFilters += processFiltersCallback(filters, variableName)
      } else {
        infoFilters += processFiltersCallback(filters)
      }
    } else {
      filters.forEach(filter => {
        const infoFilter = processFilter(filter)
        infoFilters += infoFilter
      })
    }
  }

  return { infoFilters, infoOrder, infoPage }
}

export { getListInfo }
