import LaravelPassportApiAxios from '@netberry/laravel-passport-axios'
import { ExceptionCode } from '@/exceptions/codes'
import Router from '@/routers/admin'

const exceptionsCallback = responseError => {
  // eslint-disable-next-line
  const apiUrl = `${process.env.MIX_APP_API_PROTOCOL}://${process.env.MIX_APP_API_DOMAIN}/${process.env.MIX_APP_API_PREFIX}`
  const skipResolvePaths = [
    '^' + apiUrl + '/change-password-recovery-supplier',
    '^' + apiUrl + '/recovery-password-supplier',
    '^' + apiUrl + '/users-suppliers/\\d+/change-password',
  ]
  let resolve = false
  let skipResolve = false
  const code = responseError?.response?.status
  const url = responseError?.response?.config?.baseURL + responseError?.response?.config?.url
  for (const skipPath of skipResolvePaths) {
    let escapedSkipPath = skipPath.replaceAll('/', '\\/').replaceAll('.', '\\.')
    escapedSkipPath = new RegExp(escapedSkipPath)
    if (escapedSkipPath.test(url)) {
      skipResolve = true
    }
  }

  if (!skipResolve) {
    switch (code) {
    case 403:
    case 404:
      resolve = true
      break
    default:
    }
  }

  if (resolve) {
    Router.push({ path: '/error', query: { code, message: code } })
    return Promise.resolve()
  } else {
    return Promise.reject(responseError)
  }
}

const supplierAxios = () =>
  LaravelPassportApiAxios(
    // eslint-disable-next-line no-undef
    `${process.env.MIX_APP_API_PROTOCOL}://${process.env.MIX_APP_API_DOMAIN}/${process.env.MIX_APP_API_PREFIX}`,
    {
      paths: {
        login: 'login-supplier',
        logout: 'logout-supplier',
        refresh: 'refresh-token-supplier',
      },
      access_token_name: 'access_token_supplier',
      refresh_token_name: 'refresh_token_supplier',
    },
    { exceptionCode: ExceptionCode.RefreshTokenExpiredOrRevoked },
    exceptionsCallback
  )

export { supplierAxios }
export default supplierAxios
