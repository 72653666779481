import adminAxios from '@services/adminAxios'

export default {
  index () {
    return adminAxios().get('/documents')
  },
  downloadDocument (id, type) {
    const config = {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    return adminAxios().get(`/documents/${id}/download?type=${type}`, config)
  },
}
