const DocumentType = {
  QualityCertificate: {
    translation: 'certificado-de-calidad',
    name: 'Certificado de calidad',
  },
  CertificatesRegister: {
    translation: 'registro-de-certificados',
    name: 'Registro de certificados',
  },
  StatementCompliance: {
    translation: 'declaracion-de-cumplimiento',
    name: 'Declaración de cumplimiento',
  },
}
export { DocumentType }
