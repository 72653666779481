<template>
  <div class="tab-item-full-height">
    <!--table actions-->
    <div class="tab-item-actions tab-item-actions-tabs">
      <div class="card-action-item">
        <b-button type="is-dark" class="is-fullwidth" @click="openNewDocumentModal">
          {{ $t('suppliers.orderForm.orderDocuments.addDocumentsButton') }}
        </b-button>
      </div>
      <div class="card-action-item">
        <b-button :disabled="!enabledConfirmButton"
                  type="is-primary"
                  class="is-fullwidth"
                  @click="openConfirmDocumentsModal"
        >
          {{ $t('suppliers.orderForm.orderDocuments.confirmDocumentsButton') }}
        </b-button>
      </div>
    </div>

    <!--table content tab-->
    <div class="card-content card-content-scroll">
      <b-table :data="documents"
               paginated
               :loading="loadingData"
               :default-sort-direction="defaultSortDirection"
               :default-sort="defaultSortField"
               sticky-header
               :per-page="infoPagination.per_page"
               class="table is-striped table-list-control table-scroll table-pagination"
      >
        <b-table-column v-slot="props"
                        field="reference"
                        :label="$t('suppliers.orderForm.orderDocuments.reference')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          {{ props.row.reference }}
        </b-table-column>
        <b-table-column v-slot="props"
                        field="description"
                        :label="$t('suppliers.orderForm.orderDocuments.description')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          {{ props.row.description }}
        </b-table-column>
        <b-table-column v-slot="props"
                        field="type"
                        :label="$t('suppliers.orderForm.orderDocuments.type')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          {{ $t(`suppliers.newDocumentModal.types.${props.row.type}`) }}
        </b-table-column>
        <b-table-column v-slot="props"
                        field="date"
                        :label="$t('suppliers.orderForm.orderDocuments.date')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          {{ dayjs(props.row.date).format('DD/MM/YYYY') }}
        </b-table-column>
        <b-table-column v-slot="props"
                        field="status"
                        :label="$t('suppliers.orderForm.orderDocuments.status')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          <div :class="'status ' + getStatusClass(props.row.status)">
            {{ $t(`suppliers.statusList.${props.row.status}`) }}
            <b-tooltip v-if="props.row.status === DocumentStatus.Incidence"
                       class="tooltip-icon tooltip-info"
                       type="is-white"
                       :label="getObservationsMessage(props.row.observations)"
                       multilined
                       size="is-large"
                       position="is-left"
            >
              <em class="icon-tooltip icon-custom icon-eye icon-eye-white" />
            </b-tooltip>
          </div>
        </b-table-column>
        <!--actions-->
        <b-table-column v-slot="props"
                        :label="$t('suppliers.orderForm.orderDocuments.actions')"
                        header-class="table-title"
                        centered
                        field="actions"
                        cell-class="table-actions"
        >
          <div class="table-item is-paddingless">
            <b-button class="button-square"
                      icon-pack="icon-custom"
                      icon-left="icon-eye icon-eye-gray"
                      type="is-text"
                      @click="downloadDocument(props.row.id, props.row.type, props.row.name)"
            />
          </div>
          <div class="table-item is-paddingless">
            <b-button class="button-square"
                      type="is-text"
                      icon-pack="icon-custom"
                      icon-left="icon-trash icon-trash-gray"
                      @click="deleteDocument(props.row.id, props.row.type, props.row.status)"
            />
          </div>
        </b-table-column>
        <template #empty>
          <div class="has-text-centered">
            {{ $t('suppliers.orderForm.orderDocuments.noData') }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import OrdersService from '@services/OrdersService'
import NewDocumentModal from '@components/Orders/NewDocumentModal'
import ConfirmDocumentsModal from '@components/Orders/ConfirmDocumentsModal'
import dayjs from 'dayjs'
import Notifications from '@/mixins/Notifications'
import { downloadFromBlob } from '@/helpers/FilesHelpers'
import { DocumentStatus } from '@/enums/DocumentStatus'
import { mapActions, mapGetters } from 'vuex'
import { Actions } from '@store/suppliers/types/actions'
import { Getters } from '@store/suppliers/types/getters'

export default {
  name: 'OrderDocuments',
  mixins: [Notifications],
  props: {
    numOrder: {
      type: String,
      default: null,
    },
    idOrder: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      dayjs,
      loadingData: false,
      isFullPage: false,
      documents: [],
      defaultSortDirection: 'asc',
      defaultSortField: 'date',
      infoPagination: { current_page: 1, per_page: 8, total: 0 },
      DocumentStatus,
    }
  },
  computed: {
    ...mapGetters({
      numIncidents: Getters.GET_NUMBER_INCIDENTS,
      numDocuments: Getters.GET_NUMBER_DOCUMENTS,
      orderStatus: Getters.GET_ORDER_STATUS,
      translationsPrefix: Getters.GET_TRANSLATIONS_PREFIX,
    }),
    enabledConfirmButton () {
      return this.documents.length > 0 &&
        this.orderStatus === DocumentStatus.InProgress
    },
  },
  created () {
    this.init()
  },
  methods: {
    ...mapActions({
      setNumberIncidents: Actions.SET_NUMBER_INCIDENTS,
      setNumberDocuments: Actions.SET_NUMBER_DOCUMENTS,
      setOrderStatus: Actions.SET_ORDER_STATUS,
    }),
    async init () {
      if (this.numOrder) {
        await this.getDocuments()
      }
    },
    async getDocuments () {
      this.loadingData = true
      const response = await OrdersService.indexDocuments(this.numOrder)
      this.documents = response.data
      this.setNumberDocuments(this.documents.length)
      this.documents.forEach(document => document.openIncidence = false)
      this.loadingData = false
    },
    openNewDocumentModal () {
      this.$buefy.modal.open({
        parent: this,
        component: NewDocumentModal,
        canCancel: false,
        props: {
          numOrder: this.numOrder,
          idOrder: this.idOrder,
        },
        events: {
          'new-document': async () => {
            await this.getDocuments()
            this.changeOrderStatus()
          },
        },
      })
    },
    openConfirmDocumentsModal () {
      this.$buefy.modal.open({
        parent: this,
        component: ConfirmDocumentsModal,
        canCancel: false,
        props: {
          numOrder: this.numOrder,
        },
        events: {
          'confirm-documents': () => {
            this.setOrderStatus(DocumentStatus.Pending)
          },
        },
      })
    },
    getStatusClass (status) {
      return 'status-' + status.toLowerCase().replaceAll('.', '').replaceAll(/\s/g, '-')
    },
    confirmFunctionDelete (id, { status }) {
      OrdersService.deleteDocument(id)
        .then(
          async () => {
            this.showInfo('is-success', this.$t('suppliers.orderForm.orderDocuments.msgCorrectDelete'))
            await this.getDocuments()
            this.changeNumberIncidents(status)
            if (this.documents.length > 0) {
              this.changeOrderStatus()
            }
          })
        .catch(
          () => {
            this.showInfo('is-danger', this.$t('suppliers.orderForm.orderDocuments.msgIncorrectDelete'))
          })
    },
    deleteDocument (id, type, status) {
      type = this.$t(`suppliers.newDocumentModal.types.${type}`)
      const deleteDialog = {
        id,
        aditionalData: { status },
        title: this.$t('suppliers.orderForm.orderDocuments.titleDialog'),
        message: this.$t('suppliers.orderForm.orderDocuments.messageDialog', { type }),
        onConfirmFunction: this.confirmFunctionDelete,
      }
      this.deleteDialog(deleteDialog)
    },
    downloadDocument (id, type, name) {
      OrdersService.downloadDocument(id, type)
        .then(response => {
          const type = response.headers.content_type
          const fileData = new Blob([response.data], { type })
          downloadFromBlob(fileData, name + '.pdf')
          this.showInfo('is-success', this.$t('suppliers.orderForm.orderDocuments.msgCorrect'))
        })
        .catch(() => this.showInfo('is-danger', this.$t('suppliers.orderForm.orderDocuments.msgIncorrect')))
    },
    getObservationsMessage (observations) {
      return observations ? observations : this.$t('suppliers.orderForm.orderDocuments.emptyObservations')
    },
    changeNumberIncidents (status) {
      if (status === DocumentStatus.Incidence) {
        const newNumberIncidents = this.numIncidents - 1
        this.setNumberIncidents(newNumberIncidents)
      }
    },
    changeOrderStatus () {
      if (this.orderStatus === DocumentStatus.New ||
        this.orderStatus === DocumentStatus.DocReq)
      {
        this.setOrderStatus(DocumentStatus.InProgress)
      } else if (this.orderStatus === DocumentStatus.WithIncidents &&
        this.numIncidents === 0 && this.numDocuments > 0) {
        this.setOrderStatus(DocumentStatus.Pending)
      }
    },
  },
}
</script>
