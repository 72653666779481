import supplierAxios from '@services/supplierAxios'
import _ from 'lodash'

const createForm = (data, file) => {
  const form = new FormData()

  _.mapKeys(data, function (value, key) {
    if (value !== null) {
      form.append(key, value)
    }
  })

  if (file) {
    form.append('file', file)
  }

  return form
}

export default {
  index () {
    return supplierAxios().get('/orders')
  },
  downloadDocument (id, type) {
    const config = {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    return supplierAxios().get(`/orders/${id}/download?type=${type}`, config)
  },
  updateDocument (id, data) {
    return supplierAxios().put(`/orders/${id}`, data)
  },
  deleteDocument (id) {
    return supplierAxios().delete(`/orders/${id}`)
  },
  getProductLines (id) {
    return supplierAxios().get(`/orders/${id}/product-lines`)
  },
  createDocument (data) {
    return supplierAxios().post('/orders', data)
  },
  showOrder (numOrder) {
    return supplierAxios().get(`/orders/${numOrder}`)
  },
  showDocumentStatus (numOrder) {
    return supplierAxios().get(`/orders/${numOrder}/status`)
  },
  indexInvoices (numOrder) {
    return supplierAxios().get(`/orders/${numOrder}/invoices`)
  },
  uploadInvoice (numOrder, data, file) {
    const form = createForm(data, file)
    return supplierAxios().post(`/orders/${numOrder}/upload-invoice`, form)
  },
  indexDocuments (numOrder) {
    return supplierAxios().get(`/orders/${numOrder}/documents`)
  },
  uploadDocument (numOrder, data, file) {
    const form = createForm(data, file)
    return supplierAxios().post(`/orders/${numOrder}/upload-document`, form)
  },
  getDeliveryNotes () {
    return supplierAxios().get('/orders/delivery-notes')
  },
  confirmDocuments (numOrder) {
    return supplierAxios().get(`/orders/${numOrder}/confirm-documents`)
  },
}
