<template>
  <div class="card card-document card-modal">
    <ValidationObserver ref="observer" v-slot="{invalid}">
      <!--CARD HEADER-->
      <header class="card-header">
        <p class="card-header-title">
          {{ $t('suppliers.newDocumentModal.addDocuments') }}
        </p>
        <b-button class="button-icon button-icon-close" @click="$parent.close()">
          <em class="icon-custom icon-close icon-close-gray" />
        </b-button>
      </header>
      <!--CARD BODY-->
      <div class="card-body">
        <!--card information-->
        <div class="card-content card-document-info">
          <header class="card-sub-header">
            <p class="card-header-title subtitle">
              {{ $t('suppliers.newDocumentModal.informationTitle') }}
            </p>
          </header>
          <div class="card-inner">
            <div class="columns">
              <!--delivery number invoice-->
              <div class="column">
                <TagInput v-model="selectedProductLine"
                          :tags-array="productLinesList"
                          :title="$t('suppliers.newDocumentModal.referenceAndDescription')"
                          :placeholder="$t('suppliers.newDocumentModal.referenceAndDescriptionPlaceholder')"
                          :empty-message="$t('suppliers.newDocumentModal.noProducts')"
                          :max-tags="1"
                          custom-class="required"
                          :translations-prefix="translationsPrefix"
                          :required="true"
                />
              </div>
            </div>
          </div>
        </div>
        <!--card document file type-->
        <div class="card-content card-document-upload">
          <header class="card-sub-header">
            <p class="card-header-title subtitle">
              {{ $t('suppliers.newDocumentModal.addFile') }}
            </p>
          </header>
          <div class="card-inner">
            <div class="card-copy">
              <p class="has-text-weight-medium">
                {{ $t('suppliers.newDocumentModal.addFileHelpMessage') }}
              </p>
            </div>
            <ValidationProvider v-slot="{errors}" :rules="{required: true}">
              <div class="columns">
                <div v-for="(type, index) in DocumentType" :key="index" class="column is-one-third">
                  <b-field :type="{'is-danger': errors.length}"
                           :message="errors"
                           custom-class="required"
                  >
                    <b-radio v-model="document.type"
                             :native-value="type.name"
                    >
                      {{ $t(`suppliers.newDocumentModal.types.${type.translation}`) }}
                    </b-radio>
                  </b-field>
                </div>
              </div>
            </ValidationProvider>
            <!--card document upload-->
            <ValidationProvider v-slot="{errors}" :rules="{required: true}">
              <b-field :label="$t('suppliers.newDocumentModal.uploadFile')"
                       :message="errors || $t('suppliers.newDocumentModal.validFiles')"
                       :type="{'is-danger': errors.length}"
                       custom-class="required"
              >
                <b-upload v-model="documentFile" class="file-label" accept=".pdf">
                  <span class="file-cta button button-grey">
                    <span class="file-label">
                      {{ $t('suppliers.newDocumentModal.selectFile') }}
                    </span>
                  </span>
                  <span v-if="documentFile" class="file-name">
                    <span class="file-name-copy">{{ documentFile.name }}</span>
                    <b-button class="button-icon button-icon-delete" @click="deleteFile">
                      <em class="icon-custom icon-trash icon-trash-gray" />
                    </b-button>
                  </span>
                  <span v-else class="file-name">
                    {{ $t('suppliers.newDocumentModal.noFileSelected') }}
                  </span>
                </b-upload>
              </b-field>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <!--card footer-->
      <div class="card-footer">
        <div class="card-actions">
          <div class="card-footer-item">
            <b-button type="is-outline" class="is-fullwidth" @click="$parent.close()">
              {{ $t('suppliers.newDocumentModal.cancelButton') }}
            </b-button>
          </div>
          <div class="card-footer-item">
            <b-button :disabled="invalid" type="is-primary" class="is-fullwidth" @click="uploadDocument">
              {{ $t('suppliers.newDocumentModal.addButton') }}
            </b-button>
          </div>
        </div>
      </div>
      <b-loading :is-full-page="isFullPage" :active.sync="loadingData" :can-cancel="true">
        <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin" />
      </b-loading>
    </ValidationObserver>
  </div>
</template>
<script>
import OrdersService from '@services/OrdersService'
import { DocumentType } from '@/enums/DocumentType'
import Notifications from '@/mixins/Notifications'
import TagInput from '@components/TagInput'
import { Getters } from '@store/admin/types/getters'
import { mapGetters } from 'vuex'

export default {
  name: 'NewDocumentModal',
  components: {
    TagInput,
  },
  mixins: [Notifications],
  props: {
    numOrder: {
      type: String,
      required: true,
    },
    idOrder: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      DocumentType,
      document: {
        reference: '',
        description: '',
        type: null,
      },
      documentFile: null,
      loadingData: false,
      isFullPage: true,
      selectedProductLine: [],
      productLines: [],
    }
  },
  computed: {
    ...mapGetters({
      translationsPrefix: Getters.GET_TRANSLATIONS_PREFIX,
    }),
    productLinesList () {
      return this.productLines.map(productLine => {
        const referenceAndDescription = productLine.reference + ' - ' + productLine.description
        productLine.tagName = referenceAndDescription
        return productLine
      }).filter(productLine => productLine.required) ?? []
    },
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      await this.getProductLines()
    },
    async getProductLines () {
      this.loading = true
      try {
        const results = await OrdersService.getProductLines(this.idOrder)
        this.productLines = results.data
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    uploadDocument () {
      this.loadingData = true
      const productLine = this.selectedProductLine[0]
      this.document.reference = productLine.reference
      this.document.description = productLine.description
      OrdersService.uploadDocument(this.numOrder, this.document, this.documentFile).then(() =>{
        this.showInfo('is-success', this.$t('suppliers.newDocumentModal.msgCorrect'))
        this.loadingData = false
        this.$emit('new-document')
        this.$parent.close()
      })
        .catch(
          () => {
            this.showInfo('is-danger', this.$t('suppliers.newDocumentModal.msgIncorrect'))
            this.loadingData = false
          })
    },
    deleteFile () {
      this.documentFile = null
    },
  },
}
</script>
