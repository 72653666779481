<template>
  <div v-if="!loadingData" class="card-list card-details-content card-details-order-info">
    <!--card content-->
    <div class="card-content card-content-scroll">
      <b-table sticky-header
               :data="statusList"
               class="table is-striped table-list-control table-scroll"
      >
        <!-- Date  -->
        <b-table-column v-slot="props"
                        field="date"
                        :label="$t('suppliers.orderForm.orderStatus.date')"
        >
          <div>
            {{ dayjs(props.row.date).format('DD/MM/YYYY HH:mm') }}
          </div>
        </b-table-column>
        <!-- Description  -->
        <b-table-column v-slot="props"
                        field="description"
                        :label="$t('suppliers.orderForm.orderStatus.description')"
        >
          <div>
            {{ $t(`suppliers.statusList.${props.row.description}`) }}
          </div>
        </b-table-column>
        <template #empty>
          {{ $t('suppliers.orderForm.orderStatus.noData') }}
        </template>
      </b-table>
    </div>
    <b-loading :is-full-page="isFullPage" :active.sync="loadingData" :can-cancel="true">
      <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin" />
    </b-loading>
  </div>
</template>

<script>
import OrdersService from '@services/OrdersService'
import dayjs from 'dayjs'

export default {
  name: 'OrderStatus',
  props: {
    numOrder: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      dayjs,
      loadingData: false,
      isFullPage: false,
      statusList: [],
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      if (this.numOrder) {
        this.loadingData = true
        await this.getOrderStatus()
        this.loadingData = false
      }
    },
    async getOrderStatus () {
      this.statusList = await OrdersService.showDocumentStatus(this.numOrder)
    },
  },
}
</script>
