<template>
  <div class="card card-confirm-document card-modal">
    <!--CARD MODAL HEADER-->
    <header class="card-header">
      <p class="card-header-title">
        {{ $t('suppliers.confirmDocumentsModal.confirmDocuments') }}
      </p>
      <b-button class="button-icon button-icon-close" @click="$parent.close()">
        <em class="icon-custom icon-close icon-close-gray" />
      </b-button>
    </header>
    <!--CARD MODAL CONTENT BODY-->
    <div class="card-content">
      <!--card information-->
      <div class="card-copy">
        <p class="text-size-medium has-text-centered">
          {{ $t('suppliers.confirmDocumentsModal.helpMsg') }}
        </p>
      </div>
    </div>
    <div class="card-footer">
      <div class="card-actions">
        <div class="card-footer-item">
          <b-button type="is-outline" @click="$parent.close()">
            {{ $t('suppliers.confirmDocumentsModal.cancelButton') }}
          </b-button>
        </div>
        <div class="card-footer-item">
          <b-button type="is-primary" @click="confirmDocuments">
            {{ $t('suppliers.confirmDocumentsModal.confirmDocuments') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OrdersService from '@services/OrdersService'
import Notifications from '@/mixins/Notifications'

export default {
  name: 'ConfirmDocumentsModal',
  mixins: [Notifications],
  props: {
    numOrder: {
      type: String,
      required: true,
    },
  },
  methods: {
    confirmDocuments () {
      OrdersService.confirmDocuments(this.numOrder)
        .then(
          () => {
            this.showInfo('is-success', this.$t('suppliers.confirmDocumentsModal.msgCorrectConfirm'))
            this.$emit('confirm-documents')
            this.$parent.close()
          })
        .catch(
          () => {
            this.showInfo('is-danger', this.$t('suppliers.confirmDocumentsModal.msgIncorrectConfirm'))
          })
    },
  },
}
</script>
