<template>
  <SubHeader :is-loaded="isLoaded" :structure="formStructure" />
</template>
<script>
import SubHeader from '@/components/SubHeader.vue'
import SubHeaderMixin from '@/mixins/SubHeader'
import SuppliersService from '@services/SuppliersService'
import { Getters } from '@store/admin/types/getters'
import { mapGetters } from 'vuex'

export default {
  name: 'SupplierFormSubHeader',
  components: {
    SubHeader,
  },
  mixins: [SubHeaderMixin],
  props: {
    idSupplier: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      supplier: {},
      isLoaded: true,
      backUrl: '/proveedores',
    }
  },
  computed: {
    ...mapGetters({
      translationsPrefix: Getters.GET_TRANSLATIONS_PREFIX,
    }),
    segments () {
      const segments = [
        {
          titleClass: 'text-info',
          valueClass: 'title-med',
          title: this.$t('admin.supplierFormSubHeader.supplierTitle'),
          value: this.supplier.name,
        },
        {
          titleClass: 'text-info',
          valueClass: 'subtitle',
          title: this.$t('admin.supplierFormSubHeader.codeTitle'),
          value: this.supplier.code,
        },
        {
          titleClass: 'text-info',
          valueClass: 'subtitle',
          title: this.$t('admin.supplierFormSubHeader.dateTitle'),
          value: new Date(this.supplier.created_at).toLocaleDateString(),
        },
      ]
      return segments
    },
  },
  async created () {
    if (this.idSupplier) {
      this.isLoaded = false
      await SuppliersService.showSupplier(this.idSupplier).then(
        response => {
          this.supplier = response
        }
      )
      this.isLoaded = true
    }
  },
}
</script>
