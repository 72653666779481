<template>
  <b-navbar class="is-primary">
    <!--logo-->

    <template slot="brand">
      <b-navbar-item class="brand-logo"
                     tag="router-link"
                     :to="{path: '/'}"
      >
        <!--logo image for desktop resolutions-->
        <img class="brand-logo-img brand-logo-desktop" src="@assets/logos/o-logo-white.svg" alt="logo">
        <!--logo image for mobile phones-->
        <img class="brand-logo-img brand-logo-mobile" src="@assets/logos/o-logo-white-mobile.svg" alt="logo">
        <!--portal name-->
        <div class="navbar-item-portal">
          <span class="navbar-item-portal-intro">
            {{ $t(`${translationsPrefix}.header.firstPartTitle`) }}
          </span>
          <span class="navbar-item-portal-title">
            {{ $t(`${translationsPrefix}.header.secondPartTitle`) }}
          </span>
        </div>
      </b-navbar-item>
      <div v-if="user && user.supplier_name" class="navbar-item-portal">
        <span class="navbar-item-portal-intro">
          {{ user.supplier_name }}
        </span>
      </div>
    </template>

    <!--menu secundario-->
    <template slot="end">
      <div class="nav-bar-language">
        <div class="navbar-item navbar-item-menu nav-bar-item-language">
          <slot name="languages" />
        </div>
      </div>
      <div class="nav-bar-info">
        <!--notifications button-->
        <div class="navbar-item navbar-item-menu nav-bar-item-notifications">
          <b-button
            class="nav-bar-notifications button-notifications"
            size="is-medium"
            @click="notificationsOpen = !notificationsOpen"
          >
            <!--notifications icon-->
            <div class="button-notifications-icon">
              <em class="icon-custom icon-bell icon-bell-white" />
              <span class="notification-count">
                {{ notificationsLength }}
              </span>
            </div>
            <!--notifications text button-->
            <div class="navbar-item-data navbar-notification-data">
              <span class="is-text has-text-white nav-bar-button">
                {{ $t(`${translationsPrefix}.header.notifications`) }}
              </span>
            </div>
          </b-button>
          <!--notifications popup-->
          <b-message
            v-model="notificationsOpen"
            auto-close
            :duration="5000"
            class="card card-popup card-notifications"
          >
            <NewNotifications v-model="notifications"
                              :translations-prefix="translationsPrefix"
                              @mark-unread-notifications-as-read="markUnreadNotificationsAsRead"
                              @change-read-value-notification="changeReadValueNotification($event)"
                              @go-to-order-page="goToOrderPage($event)"
            />
          </b-message>
        </div>
      </div>
      <!-- menu options-->
      <div class="navbar-item navbar-item-menu navbar-menu-main">
        <b-button v-if="user"
                  class="navbar-item navbar-profile is-paddingless"
                  @click="openUserProfileModal"
        >
          <div class="navbar-profile-id">
            {{ getFirstLetter() }}
          </div>
          <div class="navbar-item-data navbar-profile-data">
            <span class="navbar-profile-salute">
              {{ $t(`${translationsPrefix}.header.helloMsg`) }}
            </span>
            <span class="navbar-profile-name">{{ user ? user.name : '' }}</span>
          </div>
        </b-button>
      </div>
      <div class="navbar-item navbar-item-menu navbar-item-actions">
        <!--logout-->
        <b-button class="button-logout is-white"
                  size="is-medium"
                  @click="logout"
        >
          <em class="icon-custom icon-power icon-power-primary" />
        </b-button>
        <div class="navbar-item-data navbar-action-data">
          <b-button class="is-text has-text-white nav-bar-button" @click="logout">
            {{ $t(`${translationsPrefix}.header.logout`) }}
          </b-button>
        </div>
      </div>
    </template>
  </b-navbar>
</template>

<script>
import Translations from '@/mixins/Translations'
import UserProfileModal from '@components/UserProfileModal'
import NewNotifications from '@components/NewNotifications'

export default {
  name: 'Header',
  components: {
    NewNotifications,
  },
  mixins: [Translations],
  props: {
    axios: {
      type: Function,
      default: () => {
      },
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      notificationsOpen: false,
    }
  },
  computed: {
    notifications () {
      return this.user ? this.user.notifications : []
    },
    notificationsLength () {
      return this.notifications ? this.notifications.length : 0
    },
  },
  watch: {
    notificationsOpen: {
      immediate: false,
      handler (val) {
        if (!val) {
          this.markCheckedNotifications()
        }
      },
    },
  },
  methods: {
    logout() {
      this.axios().logout()
        .then(() => {
          this.$router.push('/login')
        })
        .catch(error => {
          alert(error)
        })
    },
    getFirstLetter() {
      return this.user.name.charAt(0).toUpperCase()
    },
    openUserProfileModal () {
      this.$buefy.modal.open({
        parent: this,
        component: UserProfileModal,
        canCancel: false,
        props: {
          user: this.user,
          translationsPrefix: this.translationsPrefix,
        },
        events: {
          'change-password': passwords => {
            this.$emit('change-password', passwords)
          },
          'change-active-notifications': () => {
            this.$emit('change-active-notifications')
          },
          'change-active-email-notifications': () => {
            this.$emit('change-active-email-notifications')
          },
        },
      })
    },
    markUnreadNotificationsAsRead () {
      this.$emit('mark-unread-notifications-as-read')
    },
    markCheckedNotifications () {
      this.$emit('mark-checked-notifications')
    },
    changeReadValueNotification (value) {
      this.$emit('change-read-value-notification', value)
    },
    goToOrderPage (event) {
      this.$emit('go-to-order-page', event)
    },
  },
}
</script>
