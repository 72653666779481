<template>
  <div v-if="!isLoading" class="login login-provider">
    <LoginComponent :axios="axios"
                    :redirect="redirect"
                    :translations-prefix="translationsPrefix"
                    @login="fetchUser"
                    @recover-password="recoverPassword"
    >
      <template slot="languages">
        <b-field>
          <b-select v-model="selectedLanguage"
                    class="select"
          >
            <option v-for="(language, index) in Languages" :key="index" :value="language">
              {{ $t(`${translationsPrefix}.languages.${language}`) }}
            </option>
          </b-select>
        </b-field>
      </template>
    </LoginComponent>
  </div>
</template>

<script>
import supplierAxios from '@services/supplierAxios'
import UsersSuppliersService from '@services/UsersSuppliersService'
import LoginComponent from '@components/Login'
import { Actions } from '@store/suppliers/types/actions'
import { Getters } from '@store/suppliers/types/getters'
import { mapActions, mapGetters } from 'vuex'
import Notifications from '@/mixins/Notifications'
import { Languages } from '@/enums/Languages'

export default {
  name: 'Login',
  components: {
    LoginComponent,
  },
  mixins: [Notifications],
  props: {
    redirect: {
      type: String,
      default: '/',
    },
  },
  data () {
    return {
      Languages,
      isLoading: false,
      axios: null,
    }
  },
  computed: {
    ...mapGetters({
      translationsPrefix: Getters.GET_TRANSLATIONS_PREFIX,
      language: Getters.GET_LANGUAGE,
    }),
    selectedLanguage: {
      get () {
        return this.language
      },
      set (value) {
        this.$i18n.locale = value
        this.changeLanguage(value)
      },
    },
  },
  created () {
    this.init()
  },
  methods: {
    ...mapActions({
      fetchUser: Actions.FETCH_USER,
      changeLanguage: Actions.SET_LANGUAGE,
    }),
    init () {
      this.isLoading = true
      this.axios = supplierAxios
      this.actions = Actions
      this.isLoading = false
    },
    recoverPassword (email) {
      UsersSuppliersService.askRecoveryPassword(email).then(() => {
        this.showInfo(
          'is-success',
          this.$t(`${this.translationsPrefix}.forgottenPasswordModal.correctForgottenPasswordMessage`)
        )
      }).catch (() => {
        this.showInfo(
          'is-danger',
          this.$t(`${this.translationsPrefix}.forgottenPasswordModal.incorrectForgottenPasswordMessage`)
        )
      })
    },
  },
}
</script>
