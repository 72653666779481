import Vue from 'vue'
import Router from 'vue-router'

import Login from '@views/admin/Login'
import Header from '@views/admin/Header'
import Container from '@views/admin/Container'
import NewPasswordForgotten from '@views/admin/NewPasswordForgotten'
import AdminSidebar from '@views/admin/AdminSidebar'
// Listado y formulario de proveedores
import SuppliersList from '@views/admin/Suppliers/SuppliersList'
import SuppliersListSubHeader from '@views/admin/Suppliers/SuppliersListSubHeader'
import SupplierForm from '@views/admin/Suppliers/SupplierForm'
import SupplierFormSubHeader from '@views/admin/Suppliers/SupplierFormSubHeader'
// Listado y formulario de técnicos
import TechsList from '@views/admin/Techs/TechsList'
import TechsListSubHeader from '@views/admin/Techs/TechsListSubHeader'
import TechForm from '@views/admin/Techs/TechForm'
import TechFormSubHeader from '@views/admin/Techs/TechFormSubHeader'
// Listado y formulario de técnicos
import DocumentsList from '@views/admin/Documents/DocumentsList'
import DocumentsListSubHeader from '@views/admin/Documents/DocumentsListSubHeader'

Vue.use(Router)

export default new Router({
  mode: 'history',
  // eslint-disable-next-line no-undef
  base: process.env.MIX_APP_ADMIN_PREFIX,
  routes: [
    {
      path: '/login',
      name: 'login',
      components: {
        container: Login,
      },
      meta: { requiresAuth: false },
      props: { container: route => ({ redirect: route.query.redirect }) },
    },
    {
      path: '/password',
      name: 'reset-password',
      components: {
        container: NewPasswordForgotten,
      },
      meta: { requiresAuth: false },
    },
    {
      path: '/',
      components: {
        header: Header,
        container: Container,
      },
      meta: { requiresAuth: true },
      children: [
        {
          path: '/',
          alias: '/proveedores',
          name: 'suppliers',
          components: {
            content: SuppliersList,
            sidebar: AdminSidebar,
            subHeader: SuppliersListSubHeader,
          },
        },
        {
          path: '/proveedores/:id',
          name: 'detail-suppliers',
          components: {
            content: SupplierForm,
            sidebar: AdminSidebar,
            subHeader: SupplierFormSubHeader,
          },
          props: {
            subHeader: route => ({ idSupplier: Number.parseInt(route.params.id) }),
            content: route => ({ idSupplier: Number.parseInt(route.params.id) }),
          },
        },
        {
          path: '/tecnicos',
          name: 'techs',
          meta: { onlyAdminAllowed: true },
          components: {
            content: TechsList,
            sidebar: AdminSidebar,
            subHeader: TechsListSubHeader,
          },
        },
        {
          path: '/tecnicos/:id',
          name: 'detail-tech',
          meta: { onlyAdminAllowed: true },
          components: {
            content: TechForm,
            sidebar: AdminSidebar,
            subHeader: TechFormSubHeader,
          },
          props: {
            subHeader: route => ({ idTech: Number.parseInt(route.params.id) }),
            content: route => ({ idTech: Number.parseInt(route.params.id) }),
          },
        },
        {
          path: '/archivos',
          name: 'documents',
          components: {
            content: DocumentsList,
            sidebar: AdminSidebar,
            subHeader: DocumentsListSubHeader,
          },
        },
      ],
    },
  ],
})
