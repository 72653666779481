<template>
  <div class="login-inner" @keyup.enter="login">
    <div class="card card-login">
      <div class="is-flex is-flex-direction-column is-align-items-flex-end">
        <span class="navbar-item-portal-intro">
          {{ $t(`${translationsPrefix}.header.firstPartTitle`) }}
        </span>
        <span class="navbar-item-portal-title">
          {{ $t(`${translationsPrefix}.header.secondPartTitle`) }}
        </span>
      </div>
      <div class="card-header">
        <div class="logo-color">
          <img src="@assets/logos/o-logo-color.svg" alt="logo">
        </div>
      </div>
      <div class="card-content">
        <!--email-->
        <b-field :label="$t(`${translationsPrefix}.login.email`)" custom-class="required">
          <b-input id="email"
                   v-model="email"
                   required
                   icon-pack="icon-custom"
                   icon="email email-grey-lighter"
                   :placeholder="$t(`${translationsPrefix}.login.emailPlaceholder`)"
          />
        </b-field>
        <!--password-->
        <b-field :label="$t(`${translationsPrefix}.login.password`)" custom-class="required">
          <b-input id="password"
                   v-model="password"
                   required
                   :placeholder="$t(`${translationsPrefix}.login.passwordPlaceholder`)"
                   class="input-password"
                   type="password"
                   icon-pack="icon-custom"
                   icon="lock lock-grey-lighter"
          />
        </b-field>
      </div>
      <div class="card-footer">
        <div class="card-actions is-paddingless">
          <!--login button-->
          <div class="card-footer-item">
            <b-button type="is-primary"
                      class="is-fullwidth"
                      :class="{'is-loading': isLoading}"
                      @click="login"
            >
              <span class="is-medium">
                {{ $t(`${translationsPrefix}.login.login`) }}
              </span>
            </b-button>
          </div>
          <!--forgotten password-->
          <div class="card-footer-item">
            <b-button type="is-text"
                      class="is-fullwidth"
                      @click="openForgottenPasswordModal"
            >
              {{ $t(`${translationsPrefix}.login.forgottenPassword`) }}
            </b-button>
          </div>
          <slot name="languages" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ForgottenPasswordModal from '@components/ForgottenPasswordModal'
import Translations from '@/mixins/Translations'

export default {
  name: 'Login',
  mixins: [Translations],
  props: {
    redirect: {
      type: String,
      default: '/',
    },
    axios: {
      type: Function,
      default: () => {},
    },
  },
  data () {
    return {
      email: null,
      password: null,
      isLoading: false,
    }
  },
  methods: {
    login () {
      this.isLoading = true
      this.axios().login({ email: this.email, password: this.password })
        .then(() => {
          this.setUser()
          this.$router.push(this.redirect)
        })
        .catch(error => {
          console.error('Login error', error)
          this.$buefy.snackbar.open({
            duration: 5000,
            message: this.$t(`${this.translationsPrefix}.login.incorrectLogin`),
            type: 'is-danger',
            position: 'is-bottom',
          })
        })
        .finally(() => { this.isLoading = false })
    },
    setUser () {
      this.$emit('login')
    },
    openForgottenPasswordModal () {
      this.$buefy.modal.open({
        parent: this,
        component: ForgottenPasswordModal,
        canCancel: false,
        props: {
          translationsPrefix: this.translationsPrefix,
        },
        events: {
          recover: email => {
            this.$emit('recover-password', email)
          },
        },
      })
    },
  },
}
</script>
