<template>
  <div class="tab-item-full-height">
    <!--table actions-->
    <div class="tab-item-actions tab-item-actions-tabs">
      <div class="card-action-item">
        <b-button :disabled="!enabledNewInvoiceButton" type="is-dark" class="is-fullwidth" @click="openNewInvoiceModal">
          {{ $t('suppliers.orderForm.orderInvoices.addInvoiceButton') }}
        </b-button>
      </div>
    </div>
    <!--table content tab-->
    <div class="card-content card-content-scroll">
      <b-table :data="invoices"
               paginated
               :loading="loadingData"
               :default-sort-direction="defaultSortDirection"
               :default-sort="defaultSortField"
               sticky-header
               :per-page="infoPagination.per_page"
               class="table is-striped table-list-control table-scroll table-pagination"
      >
        <b-table-column v-slot="props"
                        field="num_invoice"
                        :label="$t('suppliers.orderForm.orderInvoices.numInvoice')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          {{ props.row.num_invoice }}
        </b-table-column>
        <b-table-column v-slot="props"
                        field="date"
                        :label="$t('suppliers.orderForm.orderInvoices.date')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          {{ dayjs(props.row.date).format('DD/MM/YYYY') }}
        </b-table-column>
        <b-table-column v-slot="props"
                        field="total_amount"
                        :label="$t('suppliers.orderForm.orderInvoices.totalAmount')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          {{ props.row.total_amount }}
        </b-table-column>
        <b-table-column v-slot="props"
                        field="num_order"
                        :label="$t('suppliers.orderForm.orderInvoices.numOrder')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          {{ props.row.num_order }}
        </b-table-column>
        <b-table-column v-slot="props"
                        field="num_delivery_note"
                        :label="$t('suppliers.orderForm.orderInvoices.numDeliveryNote')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          {{ props.row.num_delivery_note }}
        </b-table-column>
        <b-table-column v-slot="props"
                        field="status"
                        :label="$t('suppliers.orderForm.orderInvoices.status')"
                        cell-class="table-data"
                        header-class="table-title"
                        sortable
        >
          <div :class="'status ' + getStatusClass(props.row.status)">
            {{ $t(`suppliers.statusList.${props.row.status}`) }}
          </div>
        </b-table-column>
        <!--actions-->
        <b-table-column v-slot="props"
                        :label="$t('suppliers.orderForm.orderInvoices.actions')"
                        header-class="table-title"
                        centered
                        field="actions"
                        cell-class="table-actions"
        >
          <div class="table-item">
            <b-button
              class="button-square"
              icon-pack="icon-custom"
              icon-left="icon-eye icon-eye-gray"
              type="is-text"
              @click="downloadDocument(props.row.id, props.row.type, props.row.name)"
            />
          </div>
        </b-table-column>
        <template #empty>
          <div class="has-text-centered">
            {{ $t('suppliers.orderForm.orderInvoices.noData') }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import OrdersService from '@services/OrdersService'
import NewInvoiceModal from '@components/Orders/NewInvoiceModal'
import dayjs from 'dayjs'
import { downloadFromBlob } from '@/helpers/FilesHelpers'
import Notifications from '@/mixins/Notifications'
import { DocumentStatus } from '@/enums/DocumentStatus'

export default {
  name: 'OrderInvoices',
  mixins: [Notifications],
  props: {
    numOrder: {
      type: String,
      default: null,
    },
    orderStatus: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      dayjs,
      loadingData: false,
      isFullPage: false,
      invoices: [],
      defaultSortDirection: 'asc',
      defaultSortField: 'date',
      infoPagination: { current_page: 1, per_page: 8, total: 0 },
      order: {},
      DocumentStatus,
    }
  },
  computed: {
    enabledNewInvoiceButton () {
      return this.orderStatus === DocumentStatus.New
        || this.orderStatus === DocumentStatus.Validated
    },
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      if (this.numOrder) {
        await this.getInvoices()
      }
    },
    async getInvoices () {
      this.loadingData = true
      const response = await OrdersService.indexInvoices(this.numOrder)
      this.invoices = response.data
      this.loadingData = false
    },
    openNewInvoiceModal () {
      this.$buefy.modal.open({
        parent: this,
        component: NewInvoiceModal,
        canCancel: false,
        props: {
          numOrder: this.numOrder,
        },
        events: {
          'new-invoice': () => {
            this.getInvoices()
            this.$emit('new-invoice')
          },
        },
      })
    },
    downloadDocument (id, type, name) {
      OrdersService.downloadDocument(id, type)
        .then(response => {
          const type = response.headers.content_type
          const fileData = new Blob([response.data], { type })
          downloadFromBlob(fileData, name + '.pdf')
          this.showInfo('is-success', this.$t('suppliers.orderForm.orderInvoices.msgCorrect'))
        })
        .catch(() => this.showInfo('is-danger', this.$t('suppliers.orderForm.orderInvoices.msgIncorrect')))
    },
    getStatusClass (status) {
      return 'status-' + status.toLowerCase().replaceAll('.', '').replaceAll(/\s/g, '-')
    },
  },
}
</script>
