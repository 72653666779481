import Vue from 'vue'
import router from '@/routers/suppliers'
import configureExceptions from '@/configureExceptions'
import { getVueI18nInstance } from '@/i18n'
import loadBuefy from '@/loadBuefy'
import store from '@store/suppliers'
import App from '@views/suppliers/App'
import { ValidationProvider, ValidationObserver, configure, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { Filter, Searcher } from '@netberry/searcher'
import supplierAxios from '@/services/supplierAxios'

configureExceptions()
loadBuefy()

const i18n = getVueI18nInstance('suppliers')

Vue.use(router)

Vue.component(Searcher.name, Searcher)
Vue.component(Filter.name, Filter)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
configure({
  bails: false,
  defaultMessage: (field, values) => i18n.t(`suppliers.validation.${values._rule_}`, values),
})
for (const rule in rules) {
  if (Object.prototype.hasOwnProperty.call(rules, rule)) {
    extend(rule, rules[rule])
  }
}

router.beforeEach(async (to, from, next) => {
  if (to.query.token) {
    localStorage.setItem('access_token_supplier', to.query.token)
    // Para que el usuario no llegue a ver el token, al menos mucho tiempo
    delete to.query.token
    return next(to.path)
  }

  const routeRequireAuth = to.matched.some(match => match.meta.requiresAuth)
  if (routeRequireAuth && !supplierAxios().loggedIn()) {
    return next({
      name: 'login',
      query: { redirect: to.fullPath },
    })
  }

  return next()
})

new Vue({
  el: '#app',
  components: { App },
  i18n,
  router,
  store,
})
